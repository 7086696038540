import { ReactNode, useMemo } from 'react';
import { CompanyContext } from '../contexts/company';
import { UseCurrentCompany } from '../hooks/use-current-company';

export type CompanyProviderProps = {
  children: ReactNode;
  useCurrentCompany: UseCurrentCompany;
}

export function CompanyProvider({
  children,
  useCurrentCompany,
}: CompanyProviderProps): JSX.Element {
  const { data: currentCompany, setCurrentCompany } = useCurrentCompany();

  const value = useMemo(() => ({
    company: currentCompany,
    setCompany: setCurrentCompany
    // eslint-disable-next-line
  }), [currentCompany]);
  return (<CompanyContext.Provider value={value}>
    {children}
  </CompanyContext.Provider>
  );
}
