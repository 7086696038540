import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { ComboBox } from '../../components/combobox';
import { createUseTopics } from '../../hooks/use-topics';
import { createUseTransactions } from '../../hooks/use-transactions';
import { Table } from '../../components/table';
import { AddTransaction } from '../../components/modal/transaction';
import { TrashIcon } from '@heroicons/react/20/solid';
import { Confirm } from '../../components/modal/confirmation';

export function createAccountBook(): () => JSX.Element {
    return function AccountBook(): JSX.Element {
        const useTopics = createUseTopics();
        const useTransactions = createUseTransactions();
      
        const { data: topics, get } = useTopics();
        const { data: transactions, get: getTransactions,
          delete: deleteTransaction
        } = useTransactions();
      
        const [selectedTopic, setSelectedTopic] = useState<any>(); 
      
        useEffect(() => {
          get();
          // eslint-disable-next-line
        }, []);
      
        useEffect(() => {
          // http://localhost:4000/api/v1/transactions?topic_id=1ff4fcc1-433f-44b0-8876-52d9b5a0da0e
          if (selectedTopic?.id) {
            getTransactions(`topic_id=${selectedTopic.id}`);
          }
            // eslint-disable-next-line
        }, [selectedTopic]);

        function refreshTxns() {
          getTransactions(`topic_id=${selectedTopic.id}`);
        }
      
        type UI_Transaction_Type = Array<{
          value: JSX.Element;
          date: JSX.Element;
          topic: JSX.Element;
          actions: JSX.Element;
        }>
      
        const creditTransactions: UI_Transaction_Type = transactions?.length ? transactions
          .filter(t => t.type === 'credit').map((t) => getTransactionRow(t, deleteTransaction, refreshTxns)) : [];
        
        if (transactions.length) {
          creditTransactions.push(getTransactionTotal(transactions.filter(t => t.type === 'credit'), 'credit'))
        }
        const debitTransactions: UI_Transaction_Type = transactions?.length ? transactions
          .filter(t => t.type === 'debit').map((t) => getTransactionRow(t, deleteTransaction, refreshTxns)): [];
        if (transactions.length) {
          debitTransactions.push(getTransactionTotal(transactions.filter(t => t.type === 'debit'), 'debit'))
        }
        const columns = [
          { Header: 'Amount', accessor: 'value' },
          { Header: 'Date', accessor: 'date' },
          { Header: 'Description', accessor: 'topic' },
          { Header: '', accessor: 'actions' }
        ];
      
        return (<div> 
          <div className='flex'>
          <div className='w-3/4'>
            <div className='px-2 lg:px-12'>
              <ComboBox data={topics} onChange={setSelectedTopic} />
            </div>
          </div>
          <div className='my-auto'>
            <AddTransaction onClose={refreshTxns} />
          </div>
          </div>
          
          { 
            transactions?.length ? <div className='flex flex-col lg:flex-row'>
              <div className="w-full md:w-1/2">
                <div className="container p-2">
                  <div className='border rounded-md'>
                    <h1 className="text-center text-lg text-rose-600">Credit</h1>
                    <Table columns={columns} data={creditTransactions} />
                  </div>
      
                </div>
              </div>
              <div className="w-full md:w-1/2">
                <div className="container p-2">
                  <div className='border rounded-md'>
                    <h1 className="text-center text-lg text-rose-600">Debit</h1>
                    <Table columns={columns} data={debitTransactions} /> 
                  </div>
      
                </div>
              </div>
            </div> : null
          }
           
        </div>);
    }
}

const yearStartDate = moment(process.env.REACT_APP_YEAR_START_DATE, "YYYY-MM-DD");


function getTransactionRow(t: any, deleteTransaction: (id: string) => void, refreshTxns: () => void) {
    const paymentMode = t.payments && t.payments[0] && t.payments[0].mode ? <>
      {
        t.payments[0].mode === 'cash' ?
          <span className='mr-2'>💵</span>:
          <span className='mr-2'>🏦</span>
          // <Cash className="text-green-900 h-6 w-6 mr-2 inline" /> : 
          // <Bank className="text-red-900 h-6 w-6 mr-2 inline" />
      }
    </> : null;
    return ({
      value: <div className="text-right">
        <div>
          <span className={t.paymentValue === t.value ? '': 'animate-pulse' }>
            {
              paymentMode
            }
            <span className="font-semibold pr-1">₹</span>{t.value.toLocaleString('en-IN')}
          </span>
        </div>
        {
          paymentMode && t.payments.map((p: any, idx: number) => <div key={idx}>
             <span className="text-xs text-gray-600 italic">
            {
              p.mode === 'cash' ? 
                // <Cash className="text-green-700 h-4 w-4 mr-2 inline" /> : 
                <span className='mr-2'>💵</span>:
                // <Bank className="text-red-700 h-4 w-4 mr-2 inline" />
                <span className='mr-2'>🏦</span>
            }
            <span className="font-semibold pr-1">₹</span>{p.value.toLocaleString('en-IN')}
          </span>
          </div>) 
        }
  
      </div>,
      date: <div>
        {t.date}
        {
          paymentMode && t.payments.map((p: any, idx: number) => <div key={idx}>
            <span className="text-xs text-gray-600 italic">{p.date}</span>
          </div>) 
        }
      </div>,
      topic: <div className='text-gray-600'>
        <div>
        { t.quantity && t.rate ? <span className="text-gray-800">({t.quantity}kg x <span className="font-semibold pr-1">₹</span>{ t.rate })</span>: '' } 
        </div>
        <div className='w-36 truncate'>
          <span className='text-sm'>[રો.પા.{moment(t.date, 'YYYY-MM-DD').diff(yearStartDate, 'days')}]&nbsp;</span>
          <span className="italic">{t.description}</span>
        </div>
      </div>,
      actions: <>
        <AddTransaction id={t.id} buttonStyle="bg-none p-2" onClose={refreshTxns} />
        <Confirm 
          buttonName={<TrashIcon className='w-4 h-4 inline text-red-400' />}
          buttonStyle={'bg-none p-0'}
          title={<>Are you sure you want to Delete <span className='text-blue-600 italic'>{t.description}</span> Transaction?</>}
          onConfirm={() =>  deleteTransaction(t.id) }
          onCancel={() => console.log(`${t.id} ${t.name} txn deletion is cancelled`)}
        />
      </>
    })
  }
  

function calculateModeTotal(t: Array<any>, mode: 'cash' | 'bank') {
    return t.reduceRight((prev, curr) => {
      let newValue: number = prev;
      if (curr.payments && curr.payments.find((p: any) => p.mode === mode)) {
        const cashPayments = curr.payments.filter((p: any) => p.mode === mode).reduceRight(
          (cashPrev: any, cashCurr: any) => cashPrev + cashCurr.value, 0
        );
  
        newValue += cashPayments;
      }
  
      return newValue;
     }, 0)
  }

function getTransactionTotal(t: Array<any>, type: 'credit' | 'debit') {
    const total = t.reduceRight((prev, curr) => prev + curr.value, 0);
    const cashTotal = calculateModeTotal(t, 'cash');
    const bankTotal = calculateModeTotal(t, 'bank');
    return ({
      value: <>
        <div className="text-right text-rose-700 text-lg"><span className="font-semibold pr-1">₹</span>{total.toLocaleString()}</div>
        <div className="text-right text-red-500 text-md">
          <span className='mr-2'>🏦</span>
          <span className="font-semibold pr-1">₹</span>{bankTotal.toLocaleString('en-IN')}
        </div>
        <div className="text-right text-green-500 text-md">
          <span className='mr-2'>💵</span>
          <span className="font-semibold pr-1">₹</span>{cashTotal.toLocaleString('en-IN')}
        </div>
        <div className="text-right text-gray-500 italic text-lg"><span className="font-semibold pr-1">₹</span>{(total - cashTotal - bankTotal).toLocaleString('en-IN')}</div>
      </>,
      date: <div></div>,
      topic: <div>
        <div className='text-rose-700 text-lg'>
          Grand Total
        </div>
        <div className='text-red-500 text-md'>
          BankTotal
        </div>
        <div className='text-green-500 text-md'>
          Cash Total
        </div>
        <div className='animate-pulse text-lg'>
          {type === 'credit' ? 'Collection' : 'Payment'} Pending
        </div>
      </div>,
      actions: <div></div>
    })
  }