import { decodeToken, isExpired } from 'react-jwt';

export type UserToken = {
  user: {
    email: string;
    name: string;
    id: string;
  };
  company: {
    id: string;
    name: string;
    details: {
      address: {
        addressLine1: string;
        addressLine2: string;
        city: string;
        state: string;
        country: string;
        pincode: string;
      };
      gst: string;
      phone: string;
      startDate: string;
      alternateName: string;
      transactionType: "inventory-linked" | "plain";
    };
  };
  iat: number;
  exp: number;
}

export function decodeJWTToken(): UserToken | null {
  const token = getUserToken();

  if (!token || isExpired(token)) {
    return null;
  }

  return decodeToken<UserToken>(token);
}

export function decodeJWTRefreshToken(): UserToken | null {
  const token = getRefreshToken();
  if (!token || isExpired(token)) {
    return null;
  }

  return decodeToken<UserToken>(token);
}

export function resetUserToken(): void {
  localStorage.removeItem('aadi-token');
  localStorage.removeItem('aadi-refresh-token');
}

export function getUserToken(): string {
  return localStorage.getItem('aadi-token') || '';
}

export function getRefreshToken(): string {
  return localStorage.getItem('aadi-refresh-token') || '';
}

export function setUserToken(token: string) {
  localStorage.setItem('aadi-token', token);
}

export function setRefreshToken(token: string) {
  localStorage.setItem('aadi-refresh-token', token);
}