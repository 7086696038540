import axios from 'axios';
import { Buffer } from 'buffer';
import { LoginReq, TokenResponse } from '../hooks/use-login';
import { setRefreshToken, setUserToken } from '../utils/token';

export async function login(req: LoginReq): Promise<TokenResponse> {
  req.password = Buffer.from(req.password).toString('base64')
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  });
  
  const res = await axiosInstance.post('/api/v1/login', req);
  if (res.status !== 200) {
    throw new Error('error failed');
  }

  setUserToken(res.data.token);
  setRefreshToken(res.data.refreshToken);
  return res.data;
}

export type RefreshTokenReq = {
  email: string;
  refreshToken: string;
}

export async function refreshToken(req: RefreshTokenReq): Promise<TokenResponse> {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  });
  
  const res = await axiosInstance.post('/api/v1/login/refresh-token', req);
  if (res.status !== 200) {
    throw new Error('error failed');
  }

  setUserToken(res.data.token);
  setRefreshToken(res.data.refreshToken);
  return res.data;
}
