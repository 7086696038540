import { Dialog, Transition } from '@headlessui/react'
import { PencilSquareIcon, PlusIcon } from '@heroicons/react/20/solid'
import { Fragment, useEffect, useState } from 'react'
import { UseShagunItems } from "../../hooks/use-shagun-items";
import QRCode from "react-qr-code";

export function AddShagunItem({ id, buttonStyle, useShagunItems, onClose }: { 
  id?: string; buttonStyle?: string; useShagunItems: UseShagunItems; onClose: () => void;
}) {
  let [isOpen, setIsOpen] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [images, setImages] = useState<Array<string>>(['']);
  const [rate, setRate] = useState<number>(0);
  const [metric, setMetric] = useState<string>('');
  const [sku, setSku] = useState<string>('');


  const { set: setShagunItem, getById : getShagunItemById } = useShagunItems();

  useEffect(() => {
    if (id && isOpen) {
      getShagunItemById(id).then(res => {
        setName(res.name);
        setDescription(res.description);
        setImages(res.images);
        setMetric(res.metric);
        setSku(res.sku);
      });
    }
  // eslint-disable-next-line
  }, [isOpen])

  function closeModal() {
    setIsOpen(false);
    onClose()
  }

  async function submitItem() {
    const res: any = {
      name: name,
      description: description,
      images: images,
      sku: sku,
      metric: metric,
      rate: rate
    }
    if (id) {
      res.id = id;
    }
    await setShagunItem(res);

    closeModal();
  }

  function openModal() {
    setIsOpen(true)
  }

  useEffect(() => {
    validate();
  // eslint-disable-next-line
  }, [name, description, images])
 
  function validate() {
    // Date can't be empty
    if (!name) {
      setErrorMessage('Name is not set');
      return;
    }

    if (!description) {
      setErrorMessage('Description is not set');
      return;
    }

    setErrorMessage('');
  }

  return (
    <>
      <div className={`${!id ? 'mx-2' : 'inline'}`}>
        <button
          type="button"
          onClick={openModal}
          className={buttonStyle || "rounded-md bg-gray-200 px-4 py-2 text-sm font-medium border border-rose-600 text-rose-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"}
        >
          {
            !id ?  <><PlusIcon className='w-4 inline' /> Add Item</> : <><PencilSquareIcon className='w-4 inline' /></>
          }
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    { !id ? 'Add Item' : name }  
                  </Dialog.Title>
                  {
                    id ? <div className='mt-2 p-4 '>
                      <QRCode className="mx-auto w-48 h-48 p-2 border border-gray-200 rounded-lg" value={id} />
                    </div> : null
                  }
                  
                  <div className="mt-2">
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                        Add Name
                      </label>
                      <input disabled={!!id} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name"
                        value={name} onChange={(val) => setName(val.target.value)} type="text" placeholder="Enter Name" />
                    </div>

                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="sku">
                        Add SKU
                      </label>
                      <input disabled={!!id} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="sku"
                        value={sku} onChange={(val) => setSku(val.target.value)} type="text" placeholder="Enter SKU" />
                    </div>

                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                        Add Description
                      </label>
                      <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="description"
                        value={description} onChange={(val) => setDescription(val.target.value)} type="text" placeholder="Enter Description" />
                    </div>

                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="images[0]">
                        Add Image Url
                      </label>
                      <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="images[0]"
                        value={images[0]} onChange={(val) => setImages([val.target.value])} type="text" placeholder="Enter Image" />
                    </div>

                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="rate">
                        Rate
                      </label>
                      <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="rate"
                        value={rate} onChange={(val) => setRate(parseInt(val.target.value || '0'))} type="text" placeholder="Enter Rate" />
                    </div>

                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="metric">
                        Metric
                      </label>
                      <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="metric"
                        value={metric} onChange={(val) => setMetric(val.target.value)} type="text" placeholder="Enter Metric" />
                    </div>

                    
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      disabled={!!errorMessage}
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-400 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:bg-gray-200"
                      onClick={submitItem}
                    >
                      Submit
                    </button>
                    <span className='text-red-500'>{errorMessage}</span>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
