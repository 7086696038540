import { useState } from "react";
import { getTopics , setTopic, deleteTopic, getTopicById} from "../services/topics";

export type UseTopics = () => {
  data: any;
  set(data: any): Promise<any>;
  // eslint-disable-next-line
  // set(path: string, data: string, ext: string): Promise<any>;
  // eslint-disable-next-line
  get(): Promise<any>;
  delete(id: string): Promise<void>;
  getById(id: string): Promise<any>;
}

export function createUseTopics(): UseTopics {
  return () => {
    // const set = async (path: string, data: string, ext: string) => {
    //     // eslint-disable-next-line
    //     // const res = await fileTransferService.set(path, data, ext);
    //     // return res
    // };

    const [topics, setTopics] = useState<any>([]);
    const get = async () => {
      const res = await getTopics();
      setTopics(res);
    }

    const getById = async (id: string): Promise<any> => {
      return getTopicById(id);
    }

    const setSync = async (data: any) => {
      await setTopic(data);
    }

    const deleteSync = async (id: string) => {
      await deleteTopic(id);
    }    


    return {
      data: topics,
      // set: (path, data, ext) => set(path, data, ext),
      get: () => get(),
      getById: (id: string) => getById(id),
      set: (data) => setSync(data),
      delete: (id: string) => deleteSync(id)
    }
  }
}