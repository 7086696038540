import moment from 'moment';
import { Dialog, Transition } from "@headlessui/react";
import {  PrinterIcon } from "@heroicons/react/20/solid";
import { Fragment, useState } from "react";
// import { UseInventoryTxns } from "../../hooks/use-inventory-txns";
import { newPurchaseOrder } from "../pdf/purchase-order";
import { ToWords } from 'to-words';
import { decodeJWTToken } from '../../utils/token';
import { formatValue } from '../../utils/currency';


export function PrintOrder({ txn }: { txn: any }) {
  const token = decodeJWTToken();
  let [isOpen, setIsOpen] = useState(false);
  let [isHidden, setIsHidden] = useState(false);
  const billType = txn.businessType === 'billed-real-goods' && txn.type === 'credit' ?
    'Sales Order' :
    txn.businessType === 'billed-real-goods' && txn.type === 'debit' ?
      'Purchase Order' :
      txn.businessType === 'billed-virtual-goods' && txn.type === 'credit' ?
        'Sales Order' :
        txn.businessType === 'billed-virtual-goods' && txn.type === 'debit' ?
          'Purchase Order' :
          txn.businessType === 'unbilled-real-goods' && txn.type === 'credit' ?
            'Sales Challan' :
            txn.businessType === 'unbilled-real-goods' && txn.type === 'debit' ?
              'Purchase Challan' : 'Unknown';

  const billSubtype = `${txn.businessType === 'unbilled-real-goods' ? 'Challan# :' : 'Bill# :'} ${
    txn.businessType === 'billed-virtual-goods' ? '☁️ ': ''} - ${
      txn.type === 'credit' ? 's': 'p'
    }${
      txn.businessType === 'billed-real-goods' ? 'br': ''
    }${
      txn.businessType === 'billed-virtual-goods' ?  'bv': ''
    }${
      txn.businessType === 'unbilled-real-goods' ? 'c': ''
    }-${
      moment(txn.createdAt).unix()
    }`;

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true)
  }

  async function print() {
    setIsHidden(true);
    await (() => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        },1000)
      });
    })();
    await newPurchaseOrder('purchase-order-id');
    setIsHidden(false);
  }

  const toWords = new ToWords();

  return (
    <>
      <div className="inline">
        <button
          type="button"
          onClick={openModal}
          className={"rounded-md bg-gray-200 px-4 py-2 text-sm font-medium text-black focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"}
        >
          {
          <PrinterIcon className='w-4 inline' />
          }
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full m-auto max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div id="purchase-order-id" className="p-5">
                    <div className="flex flex-col">
                      <div className="text-right">
                        <div className="bg-amber-600 float-right text-white text-lg w-36 p-1">{billType}</div>
                      </div>
                      <div className="text-right text-sm">
                        <span className="italic"></span>
                        <span>{billSubtype}</span>
                      </div>
                    </div>
                    <div className="mt-2 flex border border-gray-500 rounded p-2">
                      <div className="my-auto flex-none">
                        <img alt='company-logo' src={`/${token?.company.id}.png`} className='w-18 h-10' />
                      </div>
                      <div className="my-auto px-3 grow">
                        <div className="w-full flex">
                          <div className="text-xl leading-none grow">{token?.company.name}</div>
                          <div className="text-right flex-none">
                            <span className="text-xs text-rose-500 ml-5">GST: </span>
                            <span className="text-xs">{token?.company.details?.gst}</span>
                          </div>
                        </div>
                        <div className="w-full flex text-sm">
                          <div className="grow text-gray-800">
                            <div>
                              <span className="text-gray-800">
                                {token?.company.details?.address.addressLine1},
                                {' '}
                                {
                                  token?.company.details?.address.addressLine2 ? 
                                    `${token?.company.details?.address.addressLine2}, `: null
                                }
                               
                              </span>
                            </div>
                            <div>
                                {
                                  token?.company.details?.address.city
                                }
                                { ' - '}
                                {
                                  token?.company.details?.address.pincode
                                },
                                {' - '}
                                {
                                  token?.company.details?.address.state
                                },
                                {' '}
                                {
                                  token?.company.details?.address.country
                                }
                            </div>
                            
                          </div>
                          <div className="flex-none text-right text-sm">
                            📱 {token?.company.details.phone}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="border border-gray-200 flex p-2">
                        <div className="w-3/5">
                          <div className="text-sm">Bill To 📃</div>
                          <div className="text-gray-900 text-xs">
                            {/* TODO: Add bank / service icons */}
                            <div>{txn.topic?.type === 'company'?  '🏭' : ''}{txn.topic?.name}</div>
                            <div>Date 🗓️: {txn.date}</div>
                            <div>{txn.topic?.address[0]?.addressLine1}</div>
                            <div>{txn.topic?.address[0]?.addressLine2}</div>
                            <div>{txn.topic?.address[0]?.city},{txn.topic?.address[0]?.pincode}</div>
                            <div>{txn.topic?.address[0]?.state}, {txn.topic?.address[0]?.country}</div>
                            <div>GST: {txn.topic.gstNumber}</div>
                          </div>
                        </div>
                        <div className="w-2/5">
                          <div className="text-sm">Dispatch To 🚚</div>
                          <div className="text-gray-900 text-xs">
                            <div>{txn.topic?.name}</div>
                            <div>{txn.topic?.address[0]?.addressLine1}</div>
                            <div>{txn.topic?.address[0]?.addressLine2}</div>
                            <div>{txn.topic?.address[0]?.city},{txn.topic?.address[0]?.pincode}</div>
                            <div>{txn.topic?.address[0]?.state}, {txn.topic?.address[0]?.country}</div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-2">
                        <div className="border border-gray-500 p-2 rounded">
                          <div className="flex p-2">
                            <div className="text-sm w-2/5 text-center">Particulars</div>
                            <div className="text-sm w-3/5 text-right">Amount</div>
                          </div>
                          <div className="flex border border-gray-200 mb-1 p-2">
                            <div className="text-gray-900 text-xs w-2/5">
                              <div className="font-medium">{txn.item?.name}</div>
                              <div className="italic">HSN Code: {txn.item?.hsnCode}</div>
                              <div>{ txn.item?.description }</div>
                            </div>
                            <div className="text-gray-900 text-xs w-3/5 flex">
                              <div className="w-2/3 text-gray-800">
                                <div className="italic">Qty: {txn.quantity} x Rate: {txn.rate} = Taxable Value: </div>
                                {/* <div>SGST: 06%</div> */}
                                {
                                  token?.company?.details?.address?.state === txn.topic?.address[0]?.state ?
                                  <div>SGST: {txn.item?.gstSlab / 2}%</div> : 
                                  <div>IGST: {txn.item?.gstSlab / 2}%</div>
                                }
                                {/* <div>IGST: 06%</div> */}
                                <div>CGST: {txn.item?.gstSlab / 2}%</div>
                              </div>
                              <div className="w-1/3 text-right">
                                <div>{formatValue(txn.quantity * txn.rate)}</div>
                                <div>{formatValue((txn.quantity * txn.rate *  (txn.item?.gstSlab / 2) / 100))}</div>
                                <div>{formatValue((txn.quantity * txn.rate *  (txn.item?.gstSlab / 2) / 100))}</div>
                                <div className="font-bold">{formatValue(txn.value)}</div>
                              </div>
                            </div>
                          </div>
                          <div className="flex border border-gray-200 p-2">
                            <div className="text-gray-900 text-xs w-2/5">

                            </div>
                            <div className="text-gray-900 text-sm w-3/5 flex">
                              <div className="w-2/3 text-gray-900">
                                <div>Grand Total: <span className='italic'>
                                  {toWords.convert(txn.value, { currency: true })}  
                                </span></div>
                              </div>
                              <div className="w-1/3 text-right">
                                <div className="font-bold">{formatValue(txn.value)}</div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="my-2">
                          <div className='flex border border-gray-200 p-2'>
                            <div className="grow text-sm text-gray-900">
                              Description
                            </div>
                            <div className="flex-none text-gray-800 text-xs">
                              {txn.description}
                            </div>
                          </div>
                        </div>

                        <div className="my-2 border border-gray-500 rounded p-2">
                          <div className="flex p-2">
                            <div className="text-sm w-2/5 text-center">Payments</div>
                            <div className="text-sm w-3/5 text-right">Amount</div>
                          </div>
                          <div className="flex border border-gray-200 mb-1 p-2">
                            {
                              txn.payments ? txn.payments.map((p: any, idx: number) => <>
                                <div key={idx} className="text-gray-900 text-xs w-3/5"> 
                                  <div>{ p.date } { p.mode }</div>
                                  <div>{ p.note }</div>
                                </div>
                                <div className="text-gray-900 text-xs text-right w-2/5">
                                  <div className="font-bold">{formatValue(p.value)}</div>
                                </div>
                              </>) : null
                            }
                            
                          </div>
                          
                          <div className="flex border border-gray-200 p-2">
                            <div className="text-gray-900 text-xs w-2/5">

                            </div>
                            <div className="text-gray-900 text-sm w-3/5 flex">
                              <div className="w-2/3 text-gray-900">
                                <div>Grand Total: </div>
                                <div className="text-sm">Balance Pending:</div>
                              </div>
                              <div className="w-1/3 text-right">
                                <div className="font-bold">{formatValue(txn.paymentValue)}</div>
                                <div className="text-sm">{formatValue(txn.value - txn.paymentValue)}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    {
                      !isHidden ? <div className="mt-4 text-center">
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md border border-transparent bg-blue-400 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:bg-gray-200"
                          onClick={print}
                        >
                          Print
                        </button>
                      </div> : null
                    }
                    {
                      isHidden ? <div className="text-right text-xs">🖨️: { moment().format('lll') }</div> : null
                    }
                    
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
