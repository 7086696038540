import { useState } from "react";
import { GenericResourceService } from "../services/generic-resources";


export type UseResources<T> = () => {
  // eslint-disable-next-line
  set(data: T): Promise<T>;
  // eslint-disable-next-line
  data: Array<T>;
  get(filter?: any): Promise<T>;
  delete(id: string): Promise<void>;
  getById(id: string): Promise<T>;
}

export function createUseResources<T>({ route }: { route: string; }): UseResources<T> {
  const service = new GenericResourceService<T>({ route });
  
  return () => {
    const [resources, setResources] = useState<Array<T>>([]);

    const get = async (filter: any) => {
     const res = await service.getResources(filter);
     setResources(res);
     return res;
    }

    const getById = async (id: string): Promise<T> => {
      return service.getResourceById(id);
    }

    const setSync = async (data: T) => {
      return service.setResource(data);
    }

    const deleteSync = async (id: string) => {
      await service.deleteResource(id);
    }    

    return {
      data: resources,
      // set: (path, data, ext) => set(path, data, ext),
      get: (filter) => get(filter),
      getById: (id: string) => getById(id),
      set: (data) => setSync(data),
      delete: (id: string) => deleteSync(id)
    }
  }
}