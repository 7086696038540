import { useState } from "react";
import { getItems , setItem, deleteItem, getItemById} from "../services/items";

export type UseItems = () => {
  data: any;
  set(data: any): Promise<any>;
  // eslint-disable-next-line
  // set(path: string, data: string, ext: string): Promise<any>;
  // eslint-disable-next-line
  get(): Promise<any>;
  delete(id: string): Promise<void>;
  getById(id: string): Promise<any>;
}

export function createUseItems(): UseItems {
  return () => {
    // const set = async (path: string, data: string, ext: string) => {
    //     // eslint-disable-next-line
    //     // const res = await fileTransferService.set(path, data, ext);
    //     // return res
    // };

    const [items, setItems] = useState<any>([]);
    const get = async () => {
      const res = await getItems();
      setItems(res);
    }

    const getById = async (id: string): Promise<any> => {
      return getItemById(id);
    }

    const setSync = async (data: any) => {
      await setItem(data);
    }

    const deleteSync = async (id: string) => {
      await deleteItem(id);
    }    


    return {
      data: items,
      // set: (path, data, ext) => set(path, data, ext),
      get: () => get(),
      getById: (id: string) => getById(id),
      set: (data) => setSync(data),
      delete: (id: string) => deleteSync(id)
    }
  }
}