import { TrashIcon } from "@heroicons/react/20/solid";
import { useEffect } from "react";
import { AddShagunItem } from "../../components/modal/shagun-item"
import { Confirm } from "../../components/modal/confirmation";
import { Table } from "../../components/table";
import { UseShagunItems } from "../../hooks/use-shagun-items";
import { UseShagunInventory } from "../../hooks/use-shagun-inventory";

export function createShagunItemsPage({ useShagunItems, useShagunInventory }: { 
  useShagunItems: UseShagunItems;
  useShagunInventory: UseShagunInventory;
}): () => JSX.Element {
  // const InvStockSumary  = createInvStockSummary({ useTransactions });
  // const InvPaymentSummary = createInvPaymentSummary({ useTransactions });

  return function ItemsPage(): JSX.Element {
    const { data: shagunItems, get: getShagunItems, delete: deleteShagunItem } = useShagunItems();
    const columns = [
      { Header: 'Name', accessor: 'name' },
      { Header: 'Rate', accessor: 'rate' },
      { Header: 'Stock Summary', accessor: 'summary' },
      { Header: 'Payment Summary', accessor: 'payment-summary' },
      { Header: '', accessor: 'actions' }
    ];

    useEffect(( ) => {
      getShagunItems();
    // eslint-disable-next-line
    }, []);

    const data: Array<{ name: JSX.Element; rate: JSX.Element }> = shagunItems.map(((t: any) => ({
      name: <div className="flex flex-row">
        <div className="my-auto">
          {
            t.images && t.images[0] ? <img alt={t.name} className="w-14 h-14" src={t.images[0]}  /> :
              <span className="text-2xl">📦</span>
          }
        </div>
        <div className="my-auto pl-2">
          <div>{t.name}</div>
          <div className="text-sm text-gray-700">{t.description}</div>
        </div>
      </div>,
      summary: <div>
        {/* <InvStockSumary id={t.id} /> */}
      </div>,
      'payment-summary': <div>
        {/* <InvPaymentSummary id={t.id} /> */}
      </div>,
      rate: <>
        <div>{t.rate}</div>
      </>,
      actions: <>
        <AddShagunItem id={t.id} buttonStyle="bg-none p-2" useShagunItems={useShagunItems} onClose={
          () => { getShagunItems() }
        } />
        <Confirm 
          buttonName={<TrashIcon className='w-4 h-4 inline text-red-400' />}
          buttonStyle={'bg-none p-0'}
          title={<>{`Are you sure you want to Delete ${t.name} ?`}</>}
          onConfirm={() =>  deleteShagunItem(t.id) }
          onCancel={() => console.log(`${t.id} ${t.name} deletion is cancelled`)}
        /> 
        
      </>

    })))

      return (
        <div> 
          <div className='flex flex-row-reverse'>
            <div className='my-auto'>
              <AddShagunItem useShagunItems={useShagunItems} onClose={() => { getShagunItems(); }} />
            </div>
          </div>
          
          { 
            shagunItems?.length ? <div className='flex flex-col lg:flex-row'>
              <div className="w-full">
                <div className="container p-2">
                  <div className='border rounded-md'>
                    <h1 className="text-center text-2xl text-rose-600">Items 📦</h1>
                    <Table columns={columns} data={data} />
                  </div>
      
                </div>
              </div>
            </div> : null
          }
           
        </div>
      )
  }
}