import { useEffect, useState } from "react";
import { UseInventoryTxns } from "../../hooks/use-inventory-txns";
import { formatValue } from "../../utils/currency";


export function createInvStockSummary({ useTransactions }: { useTransactions: UseInventoryTxns }): ({ id }: {id: string;}) => JSX.Element {
  return function InvStockSummary({ id }: { id: string; }): JSX.Element {

    const { getSummary: getTxnSummary } = useTransactions();
    const [ summary, setSummary ] = useState<any>({});

    useEffect(() => {
      getTxnSummary(id).then(res => {
        setSummary(res.inventorySummary);
      })
    // eslint-disable-next-line
    }, []);


    return <div>
      { summary.stockSummary ?  calculateStockSummary(summary.stockSummary, 'credit', 'billed-real-goods'): <></> }
    </div>
  }
}


export function createInvPaymentSummary({ useTransactions }: { useTransactions: UseInventoryTxns }): ({ id }: {id: string;}) => JSX.Element {
  return function InvPaymentSummary({ id }: { id: string; }): JSX.Element {

    const { getSummary: getTxnSummary } = useTransactions();
    const [ summary, setSummary ] = useState<any>({});

    useEffect(() => {
      getTxnSummary(id).then(res => {
        setSummary(res.inventorySummary);
      })
    // eslint-disable-next-line
    }, []);


    return <div>
      { summary.paymentSummary ?  calculatePaymentSummary(summary.paymentSummary): <></> }
    </div>
  }
}

export function calculatePaymentSummary(summary: {
  cashPayed: number;
  cashReceived: number;
  bankPayed: number;
  bankReceived: number;
}): JSX.Element {
  const totalReceived = summary.cashReceived + summary.bankReceived;
  const totalSpent = summary.cashPayed + summary.bankPayed;

  return <div>
    <div className="flex my-2">
      <div className="my-auto bg-gray-50">
        {
         totalReceived > totalSpent ?  <>
            <div className="text-2xl">{ formatValue(totalReceived - totalSpent)}</div>
            <div className="text-md text-green-600">Profit</div>
          </> : <>
            <div className="text-2xl">{ formatValue(totalSpent - totalReceived)}</div>
            <div className="text-md text-red-600">Loss</div>
          </>
        }
      </div>
      <div className="text-sm my-auto ml-1">
        <div>🏦: <span className="text-green-600">{ formatValue(summary.bankReceived) }</span>
           { ' | ' }
           <span className="text-red-600">{ formatValue(summary.bankPayed) }</span>
        </div>
        <div>💸: <span className="text-green-600">{ formatValue(summary.cashReceived) }</span>
        { ' | ' }
           <span className="text-red-600">{ formatValue(summary.cashPayed) }</span>
        </div>
      </div>
    </div>
  </div>
}

export function calculateStockSummary(itemStock: {
  realBilledStock: number;
  realBilledStockInTransit: number;
  realUnbilledStock: number;
  virtualBilledStock: number;
}, txnType: 'credit' | 'debit', businessType: 'billed-real-goods'| 'billed-virtual-goods' | 'unbilled-real-goods',
showSummary? : boolean
 ): JSX.Element {
  return <>
    <div className="flex my-2">
      <div className="my-auto bg-gray-50">

        {
          businessType === 'billed-real-goods' ? <>
            <div className="text-2xl">{itemStock.realBilledStock || 0} KG</div>
            <div className="text-md">With Bill</div>
          </> : null
        }

        {
          businessType === 'billed-virtual-goods' ? <>
            <div className="text-2xl">{itemStock.virtualBilledStock || 0} KG</div>
            <div className="text-md">Virtual With Bill</div>
          </> : null
        }

        {
          businessType === 'unbilled-real-goods' ? <>
            <div className="text-2xl">{itemStock.realUnbilledStock || 0} KG</div>
            <div className="text-md">Without Bill</div>
          </> : null
        }
      </div>
      <div className="text-sm my-auto ml-1">
        <div>With Bill ( 🚚 <span className="italic">in Transit</span> ): {itemStock.realBilledStockInTransit || 0} KG</div>

        {
          businessType !== 'billed-real-goods' ? 
            <div>With Bill: { itemStock.realBilledStock || 0 } KG</div> : null
        }
        {
          businessType !== 'unbilled-real-goods' ? 
            <div>Without Bill: { itemStock.realUnbilledStock || 0 } KG</div> : null
        }

        {
          businessType !== 'billed-virtual-goods' ?
          <div>Virtual Stock with Bill: { itemStock.virtualBilledStock || 0 } KG</div> : null
        }        
      </div>
    </div>
    {
      showSummary ? <div className="italic text-blue-900 text-sm">
      {
        txnType === 'credit' && businessType === 'billed-real-goods' ? 
          <>
            You can sell {itemStock.realBilledStock || 0 } KG  officially.
            {
              itemStock.realBilledStockInTransit > 0 ? ` You can further sell ${ itemStock.realBilledStockInTransit } of the in-transit purchased item once it is delivered.` : ''
            }
            {
              itemStock.realUnbilledStock > 0 ? `You also have ${ itemStock.realUnbilledStock } unbilled stock, subject to you buying bills to settle books later.`: ''
            }
          </> : null
      }
      {
        txnType === 'credit' && businessType === 'billed-virtual-goods' ? 
          <>
            {
              itemStock.realBilledStock < 0 ? `You can issue Bill ${itemStock.realBilledStock } for deficit real stock.`:
              `You dont have any deficit real stock for which you should be issuing bill.`
            }
          </> : null
      } 
      {
        txnType === 'credit' && businessType === 'unbilled-real-goods' ? 
        <>
          You can sell {itemStock.realUnbilledStock || 0 } KG unbilled stock.
          {
            itemStock.realBilledStock > 0 ? `You also have ${ itemStock.realBilledStock } billed stock, subject to you selling bills to settle books later.`: ''
          }          
        </>  : null
      } 

      {
        txnType === 'debit' && businessType === 'billed-real-goods' ? 
          <>
            You have {itemStock.realBilledStock || 0 } KG real stock.
          </> : null
      }
      {
        txnType === 'debit' && businessType === 'billed-virtual-goods' ? 
        <>
          {
            itemStock.realUnbilledStock > 0 ? `You can buy Bill for ${itemStock.realBilledStock } untracked stock to settle books.`:
            `You don't have any pending untrack stock to settle books for`
          }
        </> : null
      } 
      {
        txnType === 'debit' && businessType === 'unbilled-real-goods' ? 
          <>
            You have {itemStock.realUnbilledStock || 0 } KG unbilled real stock that you have to settle book for.
          </>  : null
      } 
    </div> : null
    }
  </>}