/* eslint-disable no-unused-vars */

import { createContext } from 'react';
import { CurrentCompanyType } from '../hooks/use-current-company';

  
export const CompanyContext = createContext<{
    company: CurrentCompanyType,
    setCompany: (l: CurrentCompanyType) => void,
}>({
    company: {  } as CurrentCompanyType,
    setCompany: (l: CurrentCompanyType) => {}
});
  