import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom"
import { CompanyContext } from "../../contexts/company";
import { UseItems } from "../../hooks/use-items";
import { UseTopics } from "../../hooks/use-topics";
import { UseTransactions } from "../../hooks/use-transactions"
import { UseShagunItems } from "../../hooks/use-shagun-items";


export function createDashboard({ useTransactions, useTopics, useItems, useShagunItems }: {
  useTransactions: UseTransactions;
  useTopics: UseTopics;
  useItems: UseItems;
  useShagunItems: UseShagunItems;
}): () => JSX.Element {
  const Card = createCard()
  return function Dashboard(): JSX.Element {

    const { company } = useContext(CompanyContext);
    const { data: transactions, get: getTransactions } = useTransactions();
    const { data: topics, get: getTopics } = useTopics();
    const { data: items, get: getItems } = useItems();
    const { data: shagunItems, get: getShagunItems } = useShagunItems();
    const [summary, setSummary] = useState<{ credits: number; debits: number; balance: number; }>({
      credits: 0,
      debits: 0,
      balance: 0
    });

    useEffect(() => {
      getTransactions('');
      getTopics();
      getItems();
      getShagunItems();
    // eslint-disable-next-line
    }, []);

    useEffect(() => {

      const totalDebits = transactions.filter(t => t.type === 'debit')
        .reduceRight((prev, curr) => prev + curr.value, 0);
      const totalCredits = transactions.filter(t => t.type === 'credit')
        .reduceRight((prev, curr) => prev + curr.value, 0);
      const balance = totalCredits - totalDebits;

      setSummary({
        credits: totalCredits,
        debits: totalDebits,
        balance: balance
      })

    }, [transactions])

    return <div className="flex flex-col md:flex-row p-2">
      {
        company.name !== 'Shagun' ? <>
        { 
        ['plain', 'dual'].includes(company?.details?.transactionType) ?  
          <Card content={
            <div className="h-32">
              <div className="text-center">
                <Link to="account-book"><h1 className="text-xl">Account Book 📖</h1></Link>
              </div>
              <div>Total Transactions: {transactions.length}</div>
              <div className="text-red-800">Total Debits: ₹ {summary.debits.toLocaleString('en-IN')} </div>
              <div className="text-green-800">Total Credits: ₹ {summary.credits.toLocaleString('en-IN')}</div>
              <div className={summary.balance > 0 ? 'text-green-700' : 'text-red-700'}>
                Balance: ₹ {summary.balance.toLocaleString('en-IN')} {summary.balance > 0 ? 'Profit' : 'Loss'}
              </div>
            </div>
          } /> : null 
      }
      <Card content={
        <div className="h-32">
          <div className="text-center">
            <Link to="clients"><h1 className="text-xl">Clients 🏭</h1></Link>
          </div>
          <div>Total Clients: {topics.length}</div>
        </div>
      } />

      { ['inventory-linked', 'dual'].includes(company?.details?.transactionType) ?  
         <Card content={
          <div className="h-32">
            <div className="text-center">
              <Link to="items"><h1 className="text-xl">Items 📦</h1></Link>
            </div>
            <div>Total Items: {items.length}</div>
          </div>  
          } />
          : null
      }

      { ['inventory-linked', 'dual'].includes(company?.details?.transactionType) ?  
          <Card content={
            <div className="h-32">
              <div className="text-center">
                <Link to="inventory-txn-account-book"><h1 className="text-xl">Account Book 📖</h1></Link>
              </div>
              <div>Total Transactions: {transactions.length}</div>
              <div className="text-red-800">Total Debits: ₹ {summary.debits.toLocaleString('en-IN')} </div>
              <div className="text-green-800">Total Credits: ₹ {summary.credits.toLocaleString('en-IN')}</div>
              <div className={summary.balance > 0 ? 'text-green-700' : 'text-red-700'}>
                Balance: ₹ {summary.balance.toLocaleString('en-IN')} {summary.balance > 0 ? 'Profit' : 'Loss'}
              </div>
            </div>
            } />
          : null
      }
        </>: null
      }

      { company.name === 'Shagun' ?  <>
          <Card content={
            <div className="h-32">
              <div className="text-center">
                <Link to="shagun-items"><h1 className="text-xl">Items 📦</h1></Link>
              </div>
              <div>Total Items: {shagunItems.length}</div>
            </div>  
            } />
          <Card content={
            <div className="h-32">
              <div className="text-center">
                <Link to="customers">
                  <h1 className="text-xl">Customer Book 📖</h1>
                </Link>
              </div>
            </div>
            } />
        </>
          : null
      }
    </div>
  }
}

function createCard(): ({ content }: { content: JSX.Element }) => JSX.Element {
  return function card({ content }: { content: JSX.Element }): JSX.Element {
    return <div className="border rounded-xl shadow-lg w-full md:w-1/2 lg:w-1/4 p-2">
      {content}
    </div>
  }
}
