import { jsPDF } from "jspdf";
import { applyPlugin } from 'jspdf-autotable';
import html2canvas from "html2canvas";

applyPlugin(jsPDF);


export function printPurchaseOrderPdf(tickets: Array<any>): void {
  // initialize jsPDF
  const doc: any = new jsPDF();

  // define the columns we want and their titles
  // const tableColumn = ["Id", "Title", "Issue", "Status", "Closed on"];
  // define an empty array of rows
  const tableRows: any = [];

  // for each ticket pass all its data into an array
  tickets.forEach(ticket => {
    const ticketData = [
      ticket.id,
      ticket.title,
      ticket.request,
      ticket.status,
      ticket.updated_at
    ];
    // push each tickcet's info into a row
    tableRows.push(ticketData);
  });

  doc.autoTable({ 
    theme: 'striped',
    head: [['Id', 'Title', 'Request', 'Status', 'Updated_At']],
    body: tableRows
  });

  // we use a date string to generate our filename.
  // ticket title. and margin-top + margin-left
  doc.text("Closed tickets within the last one month.", 15, 30);
  // we define the name of our PDF file.
  doc.save(`report.pdf`);
}

export async function newPurchaseOrder(id: string): Promise<void> {
  // var doc = new jsPDF('p', 'pt', 'letter');
  const source = document.getElementById(id)

  // if (source) {
  //   console.log(source);
  //   const margins = {
  //     top: 80,
  //     bottom: 60,
  //     left: 40,
  //     width: 522
  //   };
  //   doc.html(
  //       source, // HTML string or DOM elem ref.
  //       {
  //         callback: (finalDoc) => {
  //           finalDoc.save('Test.pdf')
  //         }
  //       }     
  //   );
  // }


  if (source) {
    const canvas = await html2canvas(
    
    
      source, {
        scale: 1 // Adjusts your resolution
     });

      const imgData = canvas.toDataURL('image/png');
      // var imgWidth =  (canvas.width * 60) / 240;
      // var imgHeight = (canvas.height * 70) / 240;

      var doc: any = new jsPDF('p', 'mm', 'a4');
      // doc.addImage(imgData, 'PNG', 30, 15, imgWidth, imgHeight);
      doc.addImage(imgData, 10, 0, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 20);

      doc.save('sample-file.pdf');
  }
}