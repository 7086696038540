import { getInstance } from '../utils/axios-instance';

export async function getItems() {
  const axiosInstance = await getInstance();
  
  const res = await axiosInstance.get('/api/v1/items');
  if (res.status !== 200) {
    throw new Error('error failed');
  }

  return res.data;
}

export async function setItem(data: any) {
  const axiosInstance = await getInstance();
  
  if (data.id) {
    const res = await axiosInstance.patch(`/api/v1/items/${data.id}`, data);
    if (res.status !== 200) {
      throw new Error('error failed');
    }
  
    return res.data;
  }
  const res = await axiosInstance.post(`/api/v1/items`, data);
  if (res.status !== 200) {
    throw new Error('error failed');
  }

  return res.data;
}

export async function deleteItem(id: string) {
  const axiosInstance = await getInstance();
  
  const res = await axiosInstance.delete(`/api/v1/items/${id}`);
  if (res.status !== 200) {
    throw new Error('error failed');
  }

  return res.data;
}

export async function getItemById(id: string) {
  const axiosInstance = await getInstance();
  
  const res = await axiosInstance.get(`/api/v1/items/${id}`);
  if (res.status !== 200) {
    throw new Error('error failed');
  }

  return res.data;
}