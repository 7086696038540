import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { v4 as uuid } from 'uuid';
import { UseShagunSales } from "../../hooks/use-shagun-sales";
import { useEffect } from "react";
import moment from "moment";

export function createCustomerTransactions({
  useShagunSales
}: {
  useShagunSales: UseShagunSales
}): () => JSX.Element {
  
  return function CustomerTransactions(): JSX.Element {
    let navigate = useNavigate();
    const { data: shagunSales, get: getShagunSales } = useShagunSales();

    useEffect(() => {
      getShagunSales();
    }, []);

    function createNewTxn() {
      navigate(`/customer-transactions/${uuid()}`);
    }

    return <div>
      <div className="flex">
        <div className="w-full md:w-1/2 lg:w-1/6 ml-auto">
          <button className="bg-rose-700 text-white p-2 w-full rounded-lg" onClick={createNewTxn} >
            Create
          </button>
        </div>
      </div>
      <div>
        {
          shagunSales.map(s => <div key={s.id} className="my-0.5 p-1 border border-gray-200 rounded-lg">
            <div className="flex">
              <div className="flex-none bg-rose-200">
                <div className="grid h-full content-center">
                  <div className="p-0.5">
                    { s.status }
                  </div>
                </div>
              </div>
              <div className="grow mx-2">
                <div className="flex">
                  <div className="font-semibold">
                    <Link to={`/customer-transactions/${s.id}`}>
                      { s.name }
                    </Link>
                  </div>
                </div>
                <div className="italic text-gray-800 text-sm">
                  { moment(s.date).calendar() }
                </div> 
              </div>
              <div className="flex-none">
                <div className="grid h-full content-center">
                  <div className="p-0.5">
                    { s.salesValue }
                  </div>
                </div>
              </div>
            </div>
          </div>)
        }
      </div>
    </div>
    
    
  }
}