
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { createRoot } from "react-dom/client";
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { createAccountBook } from './pages/account-book';
import { createDashboard } from './pages/dashboard';
import { createLogin } from './pages/login';
import { createUseTransactions } from './hooks/use-transactions';
import { createUseTopics } from './hooks/use-topics';
import { createClientsPage } from './pages/clients';
import { createItemsPage } from './pages/items';
import { createUseItems } from './hooks/use-items';
import { createInventoryTxnAccountBook } from './pages/inventory-txns/account-book';
import { createUseInventoryTxns } from './hooks/use-inventory-txns';
import { createUseLogin } from "./hooks/use-login";
import { createUseCurrentUser } from "./hooks/use-current-user";
import { UserProvider } from './providers/current-user';
import { createLoginHeader } from './components/header/login-header';
import { createLoggedHeader } from './components/header/logged-header';
import { createUseCurrentCompany } from './hooks/use-current-company';
import { CompanyProvider } from './providers/current-company';
import { createCustomerTransactions } from './pages/customer-txns/account-book';
import { createCustomerTransactionById } from './pages/customer-txns/single-transaction';
import { createUseShagunItems } from './hooks/use-shagun-items';
import { createShagunItemsPage } from './pages/shagun-items';
import { createUseShagunInventory } from './hooks/use-shagun-inventory';
import { createUseShagunSales } from './hooks/use-shagun-sales';


const useTopics = createUseTopics();
const useItems = createUseItems();
const useInventoryTxns = createUseInventoryTxns();
const useLogin = createUseLogin();
const useCurrentUser = createUseCurrentUser();
const useCurrentCompany = createUseCurrentCompany();
const useShagunItems = createUseShagunItems();
const useShagunInventory = createUseShagunInventory();
const useShagunSales = createUseShagunSales();

const Login = createLogin({
  useLogin: useLogin
})
const Dashboard = createDashboard({ 
  useTransactions: createUseTransactions(),
  useTopics: useTopics,
  useItems: useItems,
  useShagunItems
});
const AccountBook = createAccountBook();
const Clients = createClientsPage({ useTopics });
const Items = createItemsPage({ useItems, useTransactions: useInventoryTxns });
const ShagunItems = createShagunItemsPage({ useShagunItems, useShagunInventory })
const InventoryTxnAccountBook = createInventoryTxnAccountBook({
  useInventoryTxns, useTopics, useItems
});
const CustomerTransactions = createCustomerTransactions({ useShagunSales });
const CustomerTransactionById = createCustomerTransactionById({ useShagunItems, useShagunSales });

const LoginHeader = createLoginHeader();
const LoggedHeader = createLoggedHeader();

const headerRouter = createBrowserRouter([
  {
    path: "/login",
    element: <>
      <LoginHeader />
      <Login />
    </>,
    
  },
  {
    path: "*",
    element: <LoggedHeader />,
    children: [
      {
        path: "",
        element: <Dashboard />
      },
      {
        path: 'account-book',
        element: <AccountBook />
      },
      {
        path: 'clients',
        element: <Clients />
      },
      {
        path: "items",
        element: <Items />
      },
      {
        path: "shagun-items",
        element: <ShagunItems />
      },
      {
        path: 'inventory-txn-account-book',
        element: <InventoryTxnAccountBook />
      },
      {
        path: 'customers',
        element: <CustomerTransactions />
      },
      {
        path: 'customer-transactions/:id',
        element: <CustomerTransactionById />
      }
    ]
  }
])


createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <UserProvider useCurrentUser={useCurrentUser}>
      <CompanyProvider useCurrentCompany={useCurrentCompany} >
        <RouterProvider router={headerRouter} />
      </CompanyProvider>
    </UserProvider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
