import { useState } from "react";
import { decodeJWTToken } from "../utils/token";

export type UseCurrentCompany = () => {
  data: CurrentCompanyType;
  setCurrentCompany: (s: CurrentCompanyType) => void;
}

export type CurrentCompanyType = {
  id: string;
  name: string;
  details: {
    address: {
      addressLine1: string;
      addressLine2: string;
      city: string;
      state: string;
      country: string;
      pincode: string;
    };
    gst: string;
    phone: string;
    startDate: string;
    alternateName: string;
    transactionType: "inventory-linked" | "plain";
  };
}

export function createUseCurrentCompany(): UseCurrentCompany {
  return () => {
    const token = decodeJWTToken();
    const initalCompanyValue: CurrentCompanyType = token ? token.company : {} as CurrentCompanyType;
    const [currentCompany, setCurrentCompany] = useState<CurrentCompanyType>(initalCompanyValue);

    return {
      data: currentCompany,
      setCurrentCompany: (req: CurrentCompanyType) => setCurrentCompany(req)
    }
  }
}