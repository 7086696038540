import { getInstance } from '../utils/axios-instance';

export async function getTransactions(filter?: any) {
  const axiosInstance = await getInstance();
  
  const res = await axiosInstance.get(`/api/v1/transactions?${filter}`);
  if (res.status !== 200) {
    throw new Error('error failed');
  }

  return res.data;
}

export async function setTransaction(data: any) {
  const axiosInstance = await getInstance();
  
  const res = await axiosInstance.post(`/api/v1/transactions`, data);
  if (res.status !== 200) {
    throw new Error('error failed');
  }

  return res.data;
}

export async function deleteTransaction(id: string) {
  const axiosInstance = await getInstance();
  
  const res = await axiosInstance.delete(`/api/v1/transactions/${id}`);
  if (res.status !== 200) {
    throw new Error('error failed');
  }

  return res.data;
}

export async function getTransactionById(id: string) {
  const axiosInstance = await getInstance();
  
  const res = await axiosInstance.get(`/api/v1/transactions/${id}`);
  if (res.status !== 200) {
    throw new Error('error failed');
  }

  return res.data;
}