import { TrashIcon } from "@heroicons/react/20/solid";
import { useEffect } from "react";
import { AddClient } from "../../components/modal/client"
import { Confirm } from "../../components/modal/confirmation";
import { Table } from "../../components/table";
import { UseTopics } from "../../hooks/use-topics";

export function createClientsPage({ useTopics }: { useTopics: UseTopics }): () => JSX.Element {

  return function ClientsPage(): JSX.Element {
    const { data: topics, get: getTopics, delete: deleteTopic } = useTopics();
    const columns = [
      { Header: 'Name', accessor: 'name' },
      { Header: 'Type', accessor: 'type' },
      { Header: '', accessor: 'actions' }
    ];

    useEffect(( ) => {
      getTopics();
    // eslint-disable-next-line
    }, []);

    const data: Array<{ name: JSX.Element; type: JSX.Element }> = topics.map(((t: any) => ({
      name: <span>{t.name}</span>,
      type: <span>{t.type}</span>,
      actions: <>
        <AddClient id={t.id} buttonStyle="bg-none p-2" onClose={() => { getTopics(); }} />
        <Confirm 
          buttonName={<TrashIcon className='w-4 h-4 inline text-red-400' />}
          buttonStyle={'bg-none p-0'}
          title={<>{`Are you sure you want to Delete ${t.name} ?`}</>}
          onConfirm={() =>  deleteTopic(t.id) }
          onCancel={() => console.log(`${t.id} ${t.name} deletion is cancelled`)}
        />
        
      </>

    })))

      return (
        <div> 
          <div className='flex flex-row-reverse'>
            <div className='my-auto'>
              <AddClient onClose={ () => { getTopics(); } } />
            </div>
          </div>
          
          { 
            topics?.length ? <div className='flex flex-col lg:flex-row'>
              <div className="w-full">
                <div className="container p-2">
                  <div className='border rounded-md'>
                    <h1 className="text-center text-lg text-rose-600">Clients</h1>
                    <Table columns={columns} data={data} />
                  </div>
      
                </div>
              </div>
            </div> : null
          }
           
        </div>
      )
  }
}