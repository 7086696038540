/* eslint-disable no-unused-vars */

import { createContext } from 'react';
import { CurrentUserType } from '../hooks/use-current-user';

  
export const UserContext = createContext<{
    user: CurrentUserType,
    setUser: (l: CurrentUserType) => void,
}>({
    user: {  } as CurrentUserType,
    setUser: (l: CurrentUserType) => {}
});
  