import { getInstance } from '../utils/axios-instance';

export async function getTopics() {
  const axiosInstance = await getInstance();
  
  const res = await axiosInstance.get('/api/v1/topics');
  if (res.status !== 200) {
    throw new Error('error failed');
  }

  return res.data;
}

export async function setTopic(data: any) {
  const axiosInstance = await getInstance();
  
  if (data.id) {
    const res = await axiosInstance.patch(`/api/v1/topics/${data.id}`, data);
    if (res.status !== 200) {
      throw new Error('error failed');
    }
  
    return res.data;
  }
  const res = await axiosInstance.post(`/api/v1/topics`, data);
  if (res.status !== 200) {
    throw new Error('error failed');
  }

  return res.data;
}

export async function deleteTopic(id: string) {
  const axiosInstance = await getInstance();
  
  const res = await axiosInstance.delete(`/api/v1/topics/${id}`);
  if (res.status !== 200) {
    throw new Error('error failed');
  }

  return res.data;
}

export async function getTopicById(id: string) {
  const axiosInstance = await getInstance();
  
  const res = await axiosInstance.get(`/api/v1/topics/${id}`);
  if (res.status !== 200) {
    throw new Error('error failed');
  }

  return res.data;
}