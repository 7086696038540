
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { UserContext } from "../../contexts/user";
import { CompanyContext } from "../../contexts/company";
import { UseLogin } from "../../hooks/use-login";
import { decodeJWTToken } from "../../utils/token";


export function createLogin({ useLogin }: {
  useLogin: UseLogin;
}): () => JSX.Element {
  return function Login(): JSX.Element {

    const { login } = useLogin();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [company, setCompany] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const { user, setUser } = useContext(UserContext);
    const { setCompany: setCurrentCompany } = useContext(CompanyContext);
    const navigate = useNavigate();

    async function submitLogin() {
      const res = await login({
        email: email,
        password: password,
        domain: company
      });
      if (res.token) {
        const decodedToken = decodeJWTToken();
        if (decodedToken) {
          const company = decodedToken.company;
          if (company) {
            setCurrentCompany(company);
          }
          const user = decodedToken.user;
          if (user) {
            setUser(user);
          }
        }
      }
    }

    useEffect(() => {
      if (user.id) {
        navigate('/');
      }
    // eslint-disable-next-line
    }, [user])

    useEffect(() => {
      setErrorMessage('');
      if (!company) {
        setErrorMessage('Company is mandatory');
        return;
      }

      if (!email) {
        setErrorMessage('Email is mandatory');
        return;
      }

      if (!password) {
        setErrorMessage('Password is mandatory');
        return;
      }


    }, [email, password, company]);

    return <div className="md:w-1/2 lg:w-1/3 mx-auto my-8 border rounded-xl border-gray-200 p-4">
        <div className="text-center text-xl text-rose-800">
          Login
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="company">
            Company
          </label>
          <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="company"
            value={company} onChange={(val) => setCompany(val.target.value)} type="text" placeholder="Enter Company" />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email"
            value={email} onChange={(val) => setEmail(val.target.value)} type="email" placeholder="Enter Email" />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
            Password
          </label>
          <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="password"
            value={password} onChange={(val) => setPassword(val.target.value)} type="password" placeholder="Enter Password" />
        </div>

        <div className="mt-4">
          <button
            type="button"
            disabled={!!errorMessage}
            className="inline-flex justify-center rounded-md border border-transparent bg-blue-400 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:bg-gray-200"
            onClick={submitLogin}
          >
            Submit
          </button>
          <span className='text-red-500'>{errorMessage}</span>
        </div>

      </div>
  }
}

