import { useState } from "react";
import { getTransactions, setTransaction, deleteTransaction, getTransactionById } from "../services/transactions";

export type UseTransactions = () => {
  // eslint-disable-next-line
  set(data: any): Promise<any>;
  // eslint-disable-next-line
  data: Array<any>;
  get(filter?: any): Promise<any>;
  delete(id: string): Promise<void>;
  getById(id: string): Promise<any>;
}

export function createUseTransactions(): UseTransactions {
  return () => {
    // const set = async (path: string, data: string, ext: string) => {
    //     // eslint-disable-next-line
    //     // const res = await fileTransferService.set(path, data, ext);
    //     // return res
    // };

    const [transactions, setTransactions] = useState<Array<any>>([]);

    const get = async (filter: any) => {
     const res = await getTransactions(filter);
     setTransactions(res);
    }

    const getById = async (id: string): Promise<any> => {
      return getTransactionById(id);
    }

    const setSync = async (data: any) => {
      await setTransaction(data);
    }

    const deleteSync = async (id: string) => {
      await deleteTransaction(id);
    }    


    return {
      data: transactions,
      // set: (path, data, ext) => set(path, data, ext),
      get: (filter) => get(filter),
      getById: (id: string) => getById(id),
      set: (data) => setSync(data),
      delete: (id: string) => deleteSync(id)
    }
  }
}