import { Dialog, Transition } from '@headlessui/react'
import { PencilSquareIcon, PlusIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { Fragment, useEffect, useState } from 'react'
import { createUseTopics } from '../../hooks/use-topics';

export type Address = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
}

export function AddClient({ id, buttonStyle, onClose }: { id?: string; buttonStyle?: string; onClose: () => void; }) {
  let [isOpen, setIsOpen] = useState(false);
  const useTopics = createUseTopics();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [name, setName] = useState<string>('');
  const [gstNumber, setGstNumber] = useState<string>('');
  const [clientType, setClientType] = useState<string>('company');
  const [address, setAddress] = useState<Array<Address>>([]);

  const { set: setTopic, getById : getTopicById } = useTopics();

  useEffect(() => {
    if (id && isOpen) {
      getTopicById(id).then(res => {
        setName(res.name);
        setGstNumber(res.gstNumber);
        setAddress(res.address);
        setClientType(res.type);
      });
    }
  // eslint-disable-next-line
  }, [isOpen])

  function closeModal() {
    setIsOpen(false);
    onClose();
  }

  function addAddress() {
    setAddress([ ...address, { 
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      pincode: '',
      country: 'India'
    } ]);
  }

  function updateAddress({ index, addressLine1, addressLine2, state, city, pincode, country }: { 
    index: number;
    addressLine1?: string;
    addressLine2?: string;
    state?: string;
    city?: string;
    pincode?: string;
    country?: string;
  }) {
    const addressToUpdate = address[index];
    if (addressLine1) {
      addressToUpdate.addressLine1 = addressLine1;
    }
    if (addressLine2) {
      addressToUpdate.addressLine2 = addressLine2;
    }
    if (city) {
      addressToUpdate.city = city;
    }
    if (state) {
      addressToUpdate.state = state;
    }
    if (pincode) {
      addressToUpdate.pincode = pincode;
    }
    if (state) {
      addressToUpdate.state = state;
    }

    setAddress([...address]);
  }

  async function submitClient() {
    const res: any = {
      name: name,
      type: clientType,
      gstNumber: gstNumber,
      address: address
    }
    if (id) {
      res.id = id;
    }
    console.log(res);
    await setTopic(res);

    closeModal();
  }

  function openModal() {
    setIsOpen(true)
  }

  function deleteAddress(idx: number) {
    address.splice(idx, 1);
    setAddress([...address]);
  }

  useEffect(() => {
    validate();
  // eslint-disable-next-line
  }, [name, clientType, gstNumber, address])
 
  function validate() {
    // Date can't be empty
    if (!name) {
      setErrorMessage('Name is not set');
      return;
    }

    // if (!gstNumber) {
    //   setErrorMessage('Gst Number is not set');
    //   return;
    // }

    if (gstNumber && (gstNumber.length !== 15 || !gstNumber.match(/^[0-9a-zA-Z]+$/))) {
      setErrorMessage('Gst Number is not valid');
      return;
    }

    if (address.length === 0) {
      setErrorMessage('Address is not set');
      return;
    }

    for( let i=0; i < address.length; i++) {
      if (!address[i].addressLine1 || !address[i].city || !address[i].pincode || !address[i].state) {
        setErrorMessage(`address is incomplete`);
        return;
      }
    }

    setErrorMessage('');
  }

  return (
    <>
      
      <div className={`${!id ? 'mx-2' : 'inline'}`}>
        <button
          type="button"
          onClick={openModal}
          className={buttonStyle || "rounded-md bg-gray-200 px-4 py-2 text-sm font-medium border border-rose-600 text-rose-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"}
        >
          {
            !id ?  <><PlusIcon className='w-4 inline' /> Add Client</> : <><PencilSquareIcon className='w-4 inline' /></>
          }
        </button>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {
                      id ? name : 'Client'
                    }
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="type">
                        Select Type
                      </label>
                      <select value={clientType} onChange={(val) => setClientType(val.target.value)} className="bg-white appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="type">
                          <option value="company">Company</option>
                          <option  value="bank">Bank</option>
                          <option value="service">Service</option>
                      </select>
                    </div>
                    
                    
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                        Add Name
                      </label>
                      <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name"
                        value={name} onChange={(val) => setName(val.target.value)} type="text" placeholder="Enter Name" />
                    </div>

                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="gstNumber">
                        Add GST #
                        {' '}
                        <span className="text-xs text-gray-500 italic">Mandatory for raising purchase/sales orders</span>
                      </label>
                      <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="gstNumber"
                        value={gstNumber} onChange={(val) => setGstNumber(val.target.value)} type="text" placeholder="Enter GST #" />
                    </div>

                    <div className="mb-2 border border-gray-200 rounded p-2">
                      <div className='flex'>
                        <div className="flex flex-grow text-lg text-gray-700 font-bold mb-2">
                          Address
                        </div>
                        <div className="inline flex-none text-gray-700 text-sm font-bold mb-2">
                          <button onClick={addAddress} className='bg-green-700 rounded text-white px-4 text-lg' type="button">+</button>
                        </div>
                      </div>
                      <div>
                        {
                          address.map((p, idx) => <div key={idx}>
                            <div className="mb-2 border border-gray-200 rounded p-2 relative">
                              {
                                idx !== 0 ? <div className="absolute top-1 right-1" onClick={() => deleteAddress(idx)} >
                                  <XMarkIcon className="w-5 h-5 cursor" />
                                </div> : null
                              }
                              <div className="mb-4 flex-initial">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`addressline-1-${idx}`}>
                                  Address Line 1
                                </label>
                                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  id={`addressline-1-${idx}`} value={address[idx].addressLine1} onChange={(val) => updateAddress({ index: idx, addressLine1: val.target.value })} placeholder="Enter Address Line 1" />
                              </div>
                              <div className="mb-4 flex-initial">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`addressline-2-${idx}`}>
                                  Address Line 2
                                </label>
                                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  id={`addressline-2-${idx}`} value={address[idx].addressLine2} onChange={(val) => updateAddress({ index: idx, addressLine2: val.target.value })} placeholder="Enter Address Line 2" />
                              </div>
                              <div className="flex">
                                <div className="w-1/2 mb-4 flex-grow">
                                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`city-${idx}`}>
                                    City
                                  </label>
                                  <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id={`city-${idx}`} value={address[idx].city} onChange={(val) => updateAddress({ index: idx, city: val.target.value }) } type="text" placeholder="Enter City" />
                                </div>
                                <div className="w-1/2 mb-4 flex-grow">
                                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`pincode-${idx}`}>
                                    Pincode
                                  </label>
                                  <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id={`pincode-${idx}`} value={address[idx].pincode} onChange={(val) => updateAddress({ index: idx, pincode: val.target.value }) } type="text" placeholder="Enter Pincode" />
                                </div>
                              </div>
                              <div className="flex">
                                <div className="w-1/2 mb-4 flex-initial">
                                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`state-${idx}`}>
                                    State
                                  </label>
                                  <select value={address[idx].state} onChange={(val) => updateAddress({ index: idx, state: val.target.value })} className="bg-white appearance-none border rounded w-18 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id={`state-${idx}`}>
                                      <option value="Andhra Pradesh">Andhra Pradesh</option>
                                      <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                      <option value="Assam">Assam</option>
                                      <option value="Bihar">Bihar</option>
                                      <option value="Chhattisgarh">Chhattisgarh</option>
                                      <option value="Goa">Goa</option>
                                      <option value="Gujarat">Gujarat</option>
                                      <option value="Haryana">Haryana</option>
                                      <option value="Himachal Pradesh">Himachal Pradesh</option>
                                      <option value="Jharkhand">Jharkhand</option>
                                      <option value="Karnataka">Karnataka</option>
                                      <option value="Kerala">Kerala</option>
                                      <option value="Madhya Pradesh">Madhya Pradesh</option>
                                      <option value="Maharashtra">Maharashtra</option>
                                      <option value="Manipur">Manipur</option>
                                      <option value="Meghalaya">Meghalaya</option>
                                      <option value="Mizoram">Mizoram</option>
                                      <option value="Nagaland">Nagaland</option>
                                      <option value="Odisha">Odisha</option>
                                      <option value="Punjab">Punjab</option>
                                      <option value="Rajasthan">Rajasthan</option>
                                      <option value="Sikkim">Sikkim</option>
                                      <option value="Tamil Nadu">Tamil Nadu</option>
                                      <option value="Telangana">Telangana</option>
                                      <option value="Tripura">Tripura</option>
                                      <option value="Uttarakhand">Uttarakhand</option>
                                      <option value="Uttar Pradesh">Uttar Pradesh</option>
                                      <option value="West Bengal">West Bengal</option>
                                  </select>
                                </div>
                                
                                <div className="w-1/2 mb-4 flex-grow">
                                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`country-${idx}`}>
                                    Country
                                  </label>
                                  <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id={`country-${idx}`} value={address[idx].country} onChange={(val) => updateAddress({ index: idx, country: val.target.value }) } type="text" placeholder="Enter Country" />
                                </div>
                              </div>
                              
                            </div>

                          </div>)
                        }
                      </div>
                      
                    </div>

                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      disabled={!!errorMessage}
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-400 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:bg-gray-200"
                      onClick={submitClient}
                    >
                      Submit
                    </button>
                    <span className='text-red-500'>{errorMessage}</span>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
