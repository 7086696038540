import { Fragment, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon, ExclamationTriangleIcon, QrCodeIcon } from '@heroicons/react/20/solid'
// import QrReader from 'react-qr-scanner'
// import QrReader from 'modern-react-qr-reader'
import {QrScanner} from '@yudiel/react-qr-scanner';


export function ComboBox({ data, onChange, disabled = false, defaultValue = {}, qr = false } : { 
  defaultValue?: any;
  disabled?: boolean;
  data: Array<{ id: string; name: string; }>,
  qr?: boolean; 
  onChange: (val: any) => void }): JSX.Element {
  
  const [selected, setSelected] = useState(
    defaultValue.id ? { id: defaultValue.id, name: defaultValue.name } :
    data[0] || 
    { id: '', name: '' })
  const [query, setQuery] = useState('');
  const [showQr, setShowQr] = useState<boolean>(false);
  const [invalidQr, setInvalidQr] = useState<boolean>(false);

  function QrWrapper(): JSX.Element {
    const [alreadyScanned, setAlreadyScanned] = useState<boolean>(false);

    function qrcodeResult(res: any) {
      if (alreadyScanned) {
        return;
      }
      if (res && showQr) {
        if (selected.id === res) {
          setShowQr(false);
          return;
        }
        const item = data.find((x: any) => {
          return x.id === res || x.sku === res || x.name === res
        });
        if (item) {
          setAlreadyScanned(true);
          setSelected({ ...item });
          onChange({ ...item })
          setShowQr(false);
        } else {
          setInvalidQr(true);
        }
      }
    }

    // return  <QrReader 
    //   delay={2000} 
    //   onScan={qrcodeResult}
    //   facingMode='environment'
    //   style={{
    //     width: '100%',
    //   }}
    //   onError={(e: any) => { console.log(e) }}
    // /> 
    return <QrScanner 
      onDecode={qrcodeResult}
      constraints={{ facingMode: 'environment' }}
      onError={(err) => console.log(err)}
      scanDelay={3000}
    />
  }


  const filteredPeople =
    query === ''
      ? data
      : data.filter((p) =>
        p.name
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
      )

  return (
    <div className="w-full">
      <Combobox value={selected} onChange={(val) => {
        setSelected(val);
        onChange(val);
      }}>
        <div className="relative mt-1">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              placeholder='Search'
              className="w-full text-center border-none py-2 pl-3 pr-10 text-lg leading-5 text-gray-900 focus:ring-0"
              displayValue={(person: { name: string; id: number; }) => person.name}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
            {
                qr ? <QrCodeIcon className="w-5 h-5 text-gray-400 absolute inline inset-y-2 right-8" onClick={() => setShowQr(!showQr) } />: ''
            }
          </div>
          {
            showQr && invalidQr ? <div className="text-center text-gray-900 border border-gray-200 rounded-md">
              <ExclamationTriangleIcon className="text-rose-600 w-5 h-5 inline" />
              {' '}
              Invalid QR-Code !!
              </div>: null
          }
          {
            showQr ? <QrWrapper />: null
          }
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options style={{ zIndex: 100 }} className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredPeople.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredPeople.map((person) => (
                  <Combobox.Option
                    key={person.id}
                    className={({ active }) =>
                      `relative text-center cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-gray-600 text-white' : 'text-gray-900'
                      }`
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                            }`}
                        >
                          {person.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                              }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}
