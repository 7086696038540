// import { Ganesh } from "../svgs/ganesh"
// import { Pray } from "../svgs/pray"
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useContext, useEffect } from "react";
import { UserContext } from "../../contexts/user";
import { CompanyContext } from "../../contexts/company";
import { resetUserToken } from "../../utils/token";


export function createLoggedHeader(): () => JSX.Element {
  return function LoggedHeader(): JSX.Element {
    const { user } = useContext(UserContext);
    const { company } = useContext(CompanyContext);
    let navigate = useNavigate();

    useEffect(() => {
      if (!user.id) {
        navigate('/login');
      }
    // eslint-disable-next-line
    }, []);

    function logout() {
      resetUserToken();
      navigate('/login');
    }

    return <>
    <div className='flex bg-slate-50 pt-2'>
      <div className='flex-none px-4'>
        <img alt={company?.id} className="h-12" src={`/${company?.id}.png`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src="/aadi-logo.png";
          }} />
        {/* <Ganesh className="w-8 inline text-rose-900" /> */}
      </div>
      <div className='grow flex'>
        <div className="flex-auto"></div>
        <div className="flex-none flex h-full">
          <div className="flex-auto">
            <Link to="/">
              <h1 className="font-serif text-center text-2xl font-bold text-rose-700">
                {/* { process.env.REACT_APP_COMPANY_NAME } {} */}
                { company?.name }
              </h1>
              <p className='text-center text-sm'>
                {/* { process.env.REACT_APP_COMPANY_NAME_2 } */}
                { company?.details?.alternateName }
              </p>
            </Link>
            
          </div>
        </div>
        <div className="flex-auto"></div>
      </div>
      <div className='hidden flex-none px-4 lg:block'>
        {/* <Pray className="text-rose-900 w-8 inline" /> */}
        
        <div className="group hover:cursor-pointer py-2 px-1">
          <div>
            <span className="bg-rose-700 text-white text-lg py-1 px-2 border-1 border-gray-200 rounded-full mr-1">
              {user.name?.substring(0,1)}
            </span>
            Hello {user.name}
          </div>
          <div className="absolute top-10 right-0 bg-gray-100 hidden group-hover:block border border-gray-200 p-2 rounded-lg text-right mr-2" onClick={
             logout
            }>
            Logout
          </div>
        </div>

      </div>
    </div>
    <div>
      <div className='px-4 lg:hidden relative'>
        {/* <Pray className="text-rose-900 w-8 inline" /> */}
        
        <div className="group hover:cursor-pointer py-2 px-1 text-right">
          <div>
            <span className="bg-rose-700 text-white text-lg py-1 px-2 border-1 border-gray-200 rounded-full mr-1">
              {user.name?.substring(0,1)}
            </span>
            Hello {user.name}
          </div>
          <div className="absolute top-10 right-0 bg-gray-100 hidden group-hover:block border border-gray-200 p-2 rounded-lg text-right mr-2" onClick={
             logout
            }>
            Logout
          </div>
        </div>

      </div>
    </div>
    <Outlet />
    </>
  }
}