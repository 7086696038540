import { getInstance } from '../utils/axios-instance';

export class GenericResourceService<T> {
  // /api/v1/shagun-items
  route: string;

  constructor({ route }: { route: string; }) {
    this.route  = route;
  }

  async getResources(filter?: any) {
    const axiosInstance = await getInstance();

    const res = await axiosInstance.get(`${this.route}${filter ? '?' + filter : ''}`);
    if (res.status !== 200) {
      throw new Error('error failed');
    }
  
    return res.data;
  }
  
  async setResource(data: T) {
    const axiosInstance = await getInstance();
    
    const res = await axiosInstance.post(`${this.route}`, data);
    if (res.status !== 200) {
      throw new Error('error failed');
    }
  
    return res.data as T;
  }
  
  async deleteResource(id: string) {
    const axiosInstance = await getInstance();
    
    const res = await axiosInstance.delete(`${this.route}/${id}`);
    if (res.status !== 200) {
      throw new Error('error failed');
    }
  }
  
  async getResourceById(id: string) {
    const axiosInstance = await getInstance();
    
    const res = await axiosInstance.get(`${this.route}/${id}`);
    if (res.status !== 200) {
      throw new Error('error failed');
    }
  
    return res.data as T;
  }
}

