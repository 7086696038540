import { useState } from "react";
import { getInventoryTxns, setInventoryTxn, deleteInventoryTxn, getInventoryTxnById,
  getInventoryTxnSummary
} from "../services/inventory-txns";

export type UseInventoryTxns = () => {
  // eslint-disable-next-line
  set(data: any): Promise<any>;
  // eslint-disable-next-line
  data: Array<any>;
  get(filter?: any): Promise<any>;
  delete(id: string): Promise<void>;
  getById(id: string): Promise<any>;
  getSummary(itemId: string): Promise<any>;
}

export function createUseInventoryTxns(): UseInventoryTxns {
  return () => {
    // const set = async (path: string, data: string, ext: string) => {
    //     // eslint-disable-next-line
    //     // const res = await fileTransferService.set(path, data, ext);
    //     // return res
    // };

    const [inventoryTxns, setInventoryTxns] = useState<Array<any>>([]);

    const get = async (filter: any) => {
     const res = await getInventoryTxns(filter);
     setInventoryTxns(res);
    }

    const getById = async (id: string): Promise<any> => {
      return getInventoryTxnById(id);
    }

    const setSync = async (data: any) => {
      await setInventoryTxn(data);
    }

    const deleteSync = async (id: string) => {
      await deleteInventoryTxn(id);
    }

    const getSummary = async (itemId: string) => {
      return getInventoryTxnSummary(itemId);
    }


    return {
      data: inventoryTxns,
      // set: (path, data, ext) => set(path, data, ext),
      get: (filter) => get(filter),
      getById: (id: string) => getById(id),
      set: (data) => setSync(data),
      delete: (id: string) => deleteSync(id),
      getSummary: (itemId: string) => getSummary(itemId),
    }
  }
}