import { useContext, useEffect, useState } from "react";
import { ComboBox } from "../../components/combobox";
import { TrashIcon } from "@heroicons/react/20/solid";
import { UseShagunItems } from "../../hooks/use-shagun-items";
import { useParams } from 'react-router-dom';
import { ShagunItem } from "../../model/shagun";
import { UseShagunSales } from "../../hooks/use-shagun-sales";
import moment from "moment";
import { CompanyContext } from "../../contexts/company";
import { useNavigate } from "react-router";

export type CustomerTransaction = {
  title: string;
  date: string;
  tenant:  { id: string; name: string; };
  customer: { id: string; name: string; mobile: string; };
  items: Array<{
    id: string;
    name: string;
    quantity: number;
    rate: number;
  }>;
  gstApplicable: boolean;
  total: number;
  payments: Array<{
    date: string;
    mode: 'upi' | 'cash' | 'bank'
  }>;
  status: 'draft' | 'open' | 'payment-settled' | 'cancelled' | 'closed';
  notes: Array<{ date: string; note: string; }>
}

export function createCustomerTransactionById({
  useShagunItems, useShagunSales
}: {
  useShagunItems: UseShagunItems;
  useShagunSales: UseShagunSales;
}): () => JSX.Element {
  
  return function CustomerTransactionById(): JSX.Element {
    let { id } = useParams();
    const { data: shagunItems, get: getShagunItems } = useShagunItems();
    const { set: saveShagunSale, getById: getShagunSaleById } = useShagunSales();
    const { company } = useContext(CompanyContext);
    const navigate = useNavigate();

    // eslint-disable-next-line
    useEffect(() => { getShagunItems(); }, []);
    useEffect(() => {
      if (shagunItems.length) {
        setItems(shagunItems);
      }
    }, [shagunItems]);

    useEffect(() => {
      if (id) {
        getShagunSaleById(id)
          .then(res => {
            console.log(res);
            if (res.id) {
              setTxn({
                title: res.name,
                customer: {
                  id: '', 
                  name: res.customerName,
                  mobile: res.customerMobile,
                },
                tenant: res.tenant,
                date: res.date,
                items: [], // TODO
                gstApplicable: false,
                total : res.salesValue,
                status: res.status,
                payments: [], // TODO
                notes: [],
              })
            }
          })
      }
    }, [id]);
    
    const [txn, setTxn] = useState<CustomerTransaction>({
      title: 'Txn Name',
      customer: {
        id: '', 
        name: 'Unknown Customer',
        mobile: '',
      },
      tenant: { id: company.id, name: company.name },
      date: moment().utc().format(),
      items: [],
      gstApplicable: false,
      total : 0,
      status: 'draft',
      payments: [],
      notes: [],
    });

    const [items, setItems] = useState<Array<ShagunItem>>([]);

    return <div className="flex flex-wrap">
      <div className="w-full font-semibold text-center m-1 p-1 border border-gray-200 rounded-lg">
        { txn.title }
      </div>

      <div className="w-full m-1 p-1 border border-gray-200 rounded-lg">
        <div className="text-center text-lg">Customer Details</div>
        <div className="w-full">
          <div>
            <input placeholder="Customer Name" className="w-full border border-100 rounded-sm p-0.5 my-0.5 text-center"
              value={txn.customer.name} onChange={(e) => {
                txn.customer.name = e.target.value;
                txn.title = `${e.target.value}'s order`;
                setTxn({ ...txn });
              }}
            />
          </div>
          <div>
            <input placeholder="Customer Mobile" className="w-full border border-100 rounded-sm my-0.5 p-0.5 text-center"
              value={txn.customer.mobile} onChange={(e) => {
                txn.customer.mobile = e.target.value;
                setTxn({ ...txn });
              }}
            />
          </div>
        </div>

      </div>

      <div className="w-full m-2 p-2 border border-gray-200 rounded-lg">
        <div className="text-center text-lg">Items</div>
        <div>
          <ComboBox qr={true} data={items} onChange={(val) => {
            if (val) {
              setTxn({
                ...txn,
                items: [
                  ...txn.items, { id: val.id, name: val.name, quantity: 1, rate: val.rate }
                ]
              })
            }
            console.log(val)
          }} />
          <div className="w-full m-1 p-1 border border-gray-200 rounded-lg">
            {
              txn.items.map((x, idx) => {
                return <div key={x.id} className="flex justify-between">
                  <div className="px-2 w-1/2">
                    { x.name } <TrashIcon onClick={() => {
                      txn.items.splice(idx, 1);
                      setTxn({ ...txn })
                    }} className="text-rose-700 inline w-5" />
                  </div>
                  <div className="flex w-1/2 justify-between">
                    <div>
                      <input type="number" value={x.quantity} onChange={(e) => {
                        x.quantity = parseInt(e.target.value || '0');
                        setTxn({ ...txn })
                      }} className="border border-gray-100 rounded-sm p-0.5 w-10" />
                    </div>
                    <div>X</div>
                    <div>
                      {
                        x.rate.toLocaleString('en-IN', {
                          maximumFractionDigits: 2,
                          style: 'currency',
                          currency: 'INR'
                        })
                      }
                    </div>
                    <div>=</div>
                    <div>
                      {
                        (x.rate * x.quantity).toLocaleString('en-IN', {
                          maximumFractionDigits: 2,
                          style: 'currency',
                          currency: 'INR'
                        })
                      }
                    </div>
                  </div>
                </div>
              })
            }

            {
              txn.items.length ? <div className="flex justify-between">
                <div className="px-2 flex-grow font-semibold">
                  Total
                </div>
                <div className="text-right">
                  {
                    (txn.items.reduce((prev, x) => {
                      return prev +(x.rate * x.quantity)
                    }, 0)).toLocaleString('en-IN', {
                      maximumFractionDigits: 2,
                      style: 'currency',
                      currency: 'INR'
                    })
                  }
                </div>
              </div>: null
            }
          </div>

          {
            txn.items.length && txn.status === 'draft' ?  <div className="w-full m-1 p-1 border border-gray-200 rounded-lg">
              <button onClick={ async () => {
                await saveShagunSale({
                  id: "",
                  tenant: txn.tenant,
                  name: txn.title,
                  disabled: false,
                  createdAt: "",
                  lastModifiedAt: "",
                  customerName: txn.customer.name,
                  customerMobile: txn.customer.mobile,
                  date: txn.date,
                  status: "draft",
                  itemDetails: [],
                  salesValue: 0,
                  paymentValue: 0,
                  payments: []
                });
                navigate(`/customers`);

              }} className="w-full text-black py-2 my-2 border rounded-lg bg-gray-200">Save as Draft</button>
              <button className="w-full text-white py-2 my-2 border rounded-lg bg-rose-700">Place Order</button>
            </div> : null
          }
         
        </div>
      </div>
    </div>
  }
}