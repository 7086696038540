import axios, { AxiosInstance } from "axios";
import { refreshToken } from "../services/login";
import { decodeJWTRefreshToken, decodeJWTToken, getRefreshToken } from "./token";


export async function getInstance(): Promise<AxiosInstance> {
  let token = decodeJWTToken();

  if (!token) {
    token = decodeJWTRefreshToken();

    if (!token) {
      throw new Error('User not logged in or expired');
    }

    await refreshToken({ 
      email: token.user.email,
      refreshToken: getRefreshToken()
     });
    token = decodeJWTToken();

    if (!token) {
      throw new Error('User still not logged after refreshing token')
    }
  }

  return axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    // baseURL: 'http://localhost:4000',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'tenant-id': token.company.id
    },
  });
}