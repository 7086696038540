import { getInstance } from '../utils/axios-instance';

export async function getInventoryTxns(filter?: any) {
  const axiosInstance = await getInstance();
  
  const res = await axiosInstance.get(`/api/v1/inventory-txns?${filter}`);
  if (res.status !== 200) {
    throw new Error('error failed');
  }

  return res.data;
}

export async function setInventoryTxn(data: any) {
  const axiosInstance = await getInstance();
  
  const res = await axiosInstance.post(`/api/v1/inventory-txns`, data);
  if (res.status !== 200) {
    throw new Error('error failed');
  }

  return res.data;
}

export async function deleteInventoryTxn(id: string) {
  const axiosInstance = await getInstance();
  
  const res = await axiosInstance.delete(`/api/v1/inventory-txns/${id}`);
  if (res.status !== 200) {
    throw new Error('error failed');
  }

  return res.data;
}

export async function getInventoryTxnById(id: string) {
  const axiosInstance = await getInstance();
  const res = await axiosInstance.get(`/api/v1/inventory-txns/${id}`);
  if (res.status !== 200) {
    throw new Error('error failed');
  }

  return res.data;
}

export async function getInventoryTxnSummary(itemId: string) {
  const axiosInstance = await getInstance();
  
  const res = await axiosInstance.get(`/api/v1/inventory-txns/summary/${itemId}`);
  if (res.status !== 200) {
    throw new Error('error failed');
  }
  return res.data;
}
