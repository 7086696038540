import { Outlet } from "react-router";


export function createLoginHeader(): () =>  JSX.Element {
  return function LoginHeader(): JSX.Element {
    return <>
      <div className='bg-slate-50 p-2 text-rose-500 text-center flex'>
        <div className="flex-none">
          <img className="text-center w-8" src="/aadi-logo.png" alt="aadi-logo" />
        </div>
        <div className="flex-grow my-auto text-lg">
         Aadi Lite
        </div>
      </div>
      <Outlet/>
    </>
  }
}