import moment from 'moment';
import { Dialog, Transition } from '@headlessui/react'
import { PencilSquareIcon, PlusIcon } from '@heroicons/react/20/solid'
import { Fragment, useEffect, useState } from 'react'
import { createUseTopics } from '../../hooks/use-topics';
import { ComboBox } from '../combobox';
import { createUseTransactions } from '../../hooks/use-transactions';

export function AddTransaction({ id, buttonStyle, onClose }: { id?: string; buttonStyle?: string; onClose: () => void; }) {
  let [isOpen, setIsOpen] = useState(false);
  const useTopics = createUseTopics();
  const useTransactions = createUseTransactions();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [selectedTopic, setSelectedTopic] = useState<any>();
  useEffect(() => {
    get();
  // eslint-disable-next-line
  }, []);

  const [quantity, setQuantity] = useState<number>(0);
  const [rate, setRate] = useState<number>(0);
  const [amount, setAmount] = useState<number>(0);
  const [txnType, setTxnType] = useState<string>('credit');
  const [payments, setPayments] = useState<Array<{
    mode: 'cash' | 'bank';
    value: number;
    note: string;
    date: string;
  }>>([ { mode: 'cash', value: 0, note: '', date: moment().format('YYYY-MM-DD') }  ]);
  const [paymentValue, setPaymentValue] = useState<number>(0);

  function updatePayment({ index, value, note, mode }: { 
    index: number;
    value?: number;
    mode?: 'cash' | 'bank';
    note?: string;
  }) {
    const newPayment = payments[index];
    if (value) {
      newPayment.value = value;
    }
    if (note) {
      newPayment.note = note;
    }
    if (mode) {
      newPayment.mode = mode;
    }
    newPayment.date = moment().format('YYYY-MM-DD');

    setPayments([...payments]);
    setPaymentValue(payments.reduce((p, c) => p + c.value, 0));
  }

  function addPayment() {
    setPayments([ ...payments, { mode: 'cash', value: 0, note: '', date: moment().format('YYYY-MM-DD') } ]);
  }

  useEffect(() => {
    if (rate && quantity) {
      setAmount(rate * quantity);
    }
  }, [rate, quantity]);

  const [description, setDescription] = useState<string>('');
  const [date, setDate] = useState<string>(moment().format('YYYY-MM-DD'))

  const { data: topics, get, getById: getTopicById } = useTopics();

  const { set, getById: getTxnById } = useTransactions();

  useEffect(() => {
    if (id && isOpen) {
      getTxnById(id).then(res => {
        setDescription(res.description);
        setDate(res.date);
        setPaymentValue(res.paymentValue);
        setPayments(res.payments);
        setQuantity(res.quantity);
        setRate(res.rate);
        getTopicById(res.topic.id)
          .then(result => setSelectedTopic(result));
        setTxnType(res.type);
        setAmount(res.value);
      })
    }
  // eslint-disable-next-line
  }, [isOpen])

  function closeModal() {
    setIsOpen(false);
    onClose();
  }

  async function submitTransaction() {
    const res: any = {
      topic: selectedTopic,
      type: txnType,
      date: date,
      description: description,
      quantity: quantity || undefined,
      rate: rate || undefined,
      value: amount,
      payments: payments.filter(p => p.value),
      paymentValue: paymentValue
    };

    if (id) {
      res.id = id;
    }

    await set(res);

    closeModal();
  }

  function openModal() {
    setIsOpen(true)
  }

  useEffect(() => {
    validate();
  // eslint-disable-next-line
  }, [quantity, rate, amount, txnType, payments, paymentValue, amount, description, date])
 
  function validate() {
    // Date can't be empty
    if (!date) {
      setErrorMessage('Date is not set');
      return;
    }

    // Amount should be greater than 0
    if (amount < 1) {
      setErrorMessage("Amount should be greater than zero");
      return;
    }

    // Client needs to be selected
    if (!!!selectedTopic?.id) {
      setErrorMessage('No client selected');
      return;
    }

    if (paymentValue > amount) {
      setErrorMessage('Total Payment cant exceed Total Transaction Amount');
      return;
    }

    setErrorMessage('');

  }

  return (
    <>
      <div className={`${!id ? 'mx-2' : 'inline'}`}>
        <button
          type="button"
          onClick={openModal}
          className={"rounded-md bg-gray-200 px-4 py-2 text-sm font-medium  border border-rose-600 text-rose-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"}
        >
          {
          !id ?  <><PlusIcon className='w-4 inline' /> Add Txn</> : <><PencilSquareIcon className='w-4 inline' /></>
        }
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Transaction
                  </Dialog.Title>
                  <div className="mt-2">
                    <div>
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="amount">
                        Select Client
                      </label>
                      <ComboBox data={topics} onChange={(val) => {
                        const selTopic = topics.find((i: any) => i.id === val.id);
                        if (selTopic) {
                          setSelectedTopic(val);
                        }
                      }} />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="type">
                        Select Type
                      </label>
                      <select value={txnType} onChange={(val) => setTxnType(val.target.value)} className="bg-white appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="type">
                          <option value="credit">Credit</option>
                          <option value="debit">Debit</option>
                      </select>
                    </div>
                    <div>
                      <div className="datepicker relative form-floating mb-3">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="date">
                          Select Date
                        </label>
                        <input type="date" id="date"
                          className="w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-700 focus:outline-none"
                          placeholder="Select a date" onChange={(val) => setDate(val.target.value)} value={date} />
                      </div>
                    </div>
                    <div className="flex">
                      <div className="mb-4 flex-initial">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="quantity">
                          Quantity
                        </label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="quantity" value={quantity} onChange={(val) => setQuantity(parseInt(val.target.value || '0'))} type="number" placeholder="Enter Quantity" />
                      </div>
                      <div className='flex-initial my-auto'>
                        X
                      </div>
                      <div className="mb-4 flex-initial">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="rate">
                          Rate
                        </label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="rate" value={rate} onChange={(val) => setRate(parseInt(val.target.value || '0'))} type="number" placeholder="Enter Rate" />
                      </div>
                      <div className='flex-initial my-auto'>
                        =
                      </div>
                      <div className="mb-4 flex-grow">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="amount">
                          Total Amount
                        </label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="amount" readOnly value={amount} onChange={(val) => setAmount(parseInt(val.target.value || '0'))} type="number" placeholder="Enter Amount" />
                      </div>
                    </div>
                    
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                        Add Description
                      </label>
                      <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="description"
                        value={description} onChange={(val) => setDescription(val.target.value)} type="text" placeholder="Enter Description" />
                    </div>

                    <div className="mb-4">
                      <div className='flex'>
                        <div className="flex flex-grow text-lg text-gray-700 font-bold mb-2">
                          Payments { txnType === 'credit' ? 'Received': 'Made' }: { paymentValue }
                        </div>
                        <div className="inline flex-none text-gray-700 text-sm font-bold mb-2">
                          <button onClick={addPayment} className='bg-green-700 rounded text-white px-4 text-lg' type="button">+</button>
                        </div>
                      </div>
                      <div>
                        {
                          payments.map((p, idx) => <div key={idx}>
                            <div className="flex">
                              <div className="mb-4 flex-initial">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`payment-value-${idx}`}>
                                  Value
                                </label>
                                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  id={`payment-value-${idx}`} value={payments[idx].value} onChange={(val) => updatePayment({ index: idx, value: parseInt(val.target.value || '0') })} type="number" placeholder="Enter Value" />
                              </div>
                              <div className="mb-4 flex-initial">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`payment-mode-${idx}`}>
                                  Mode
                                </label>
                                <select value={payments[idx].mode} onChange={(val) => updatePayment({ index: idx, mode: val.target.value as 'cash' | 'bank' })} className="bg-white appearance-none border rounded w-18 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  id={`payment-mode-${idx}`}>
                                    <option value="cash">Cash</option>
                                    <option value="bank">Bank</option>
                                </select>
                              </div>
                              <div className="mb-4 flex-grow">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`payment-note-${idx}`}>
                                  Note
                                </label>
                                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  id={`payment-note-${idx}`} value={payments[idx].note} onChange={(val) => updatePayment({ index: idx, note: val.target.value }) } type="text" placeholder="Enter Note" />
                              </div>
                            </div>

                          </div>)
                        }
                      </div>
                      
                    </div>

                  </div>

                  <div className='mt-2'>
                    <div className="text-lg text-gray-700 font-bold mb-2">
                        Balance: { amount - paymentValue }
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      disabled={!!errorMessage}
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-400 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:bg-gray-200"
                      onClick={submitTransaction}
                    >
                      Submit
                    </button>
                    <span className='text-red-500'>{errorMessage}</span>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
