import { useState } from "react";
import { decodeJWTToken } from "../utils/token";

export type UseCurrentUser = () => {
  data: CurrentUserType;
  setCurrentUser: (s: CurrentUserType) => void;
}

export type CurrentUserType = {
  id: string;
  name: string;
  email: string;
}

export function createUseCurrentUser(): UseCurrentUser {
  return () => {
    const token = decodeJWTToken();
    const initalUserValue: CurrentUserType = token ? token.user : {} as CurrentUserType;
    const [currentUser, setCurrentUser] = useState<CurrentUserType>(initalUserValue);

    return {
      data: currentUser,
      setCurrentUser: (req: CurrentUserType) => setCurrentUser(req)
    }
  }
}