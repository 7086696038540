import { login as serviceLogin } from "../services/login";

export type TokenResponse = {
  token: JWTToken;
  refreshToken: RefreshToken;
};
export type JWTToken = string;
export type RefreshToken = string;

export type LoginReq = {
  email: string;
  password: string;
  domain: string;
}

export type UseLogin = () => {
  login(loginReq: LoginReq): Promise<TokenResponse>
}

export function createUseLogin(): UseLogin {
  return () => {

    const login = async (req: LoginReq): Promise<TokenResponse> => {
      return serviceLogin(req);
    }

    return {
      login: (req: LoginReq) => login(req)
    }
  }
}