import { Dialog, Transition } from "@headlessui/react";
import { PencilSquareIcon, PlusIcon } from "@heroicons/react/20/solid";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { UseInventoryTxns } from "../../hooks/use-inventory-txns";
import { UseItems } from "../../hooks/use-items";
import { UseTopics } from "../../hooks/use-topics";
import { formatValue } from "../../utils/currency";
import { ComboBox } from "../combobox";
import { calculateStockSummary } from "../inventory-summary";


export function AddInventoryTxn({ id, buttonStyle, useTxns, useTopics, useItems, onClose}: {
  id?: string; buttonStyle?: string; useTxns: UseInventoryTxns;
  useTopics: UseTopics; useItems: UseItems; onClose: () => void;
}) {
  let [isOpen, setIsOpen] = useState(false);

  const { data: topics, get: getTopics, getById: getTopicById } = useTopics();
  const { data: items, get: getItems, getById: getItemById } = useItems();
  const { set, getById: getTxnById, getSummary: getTxnSummary } = useTxns();


  const [errorMessage, setErrorMessage] = useState<string>('');

  const [businessType, setBusinessType] = useState<string>('billed-real-goods');

  const [selectedTopic, setSelectedTopic] = useState<any>();

  const [selectedItem, setSelectedItem] = useState<any>({ id:'', name: '' });
  const [selectedItemSummary, setSelectedItemSummary] = useState<any>();

  useEffect(() => {
    if (selectedItem) {
      getTxnSummary(selectedItem.id).then(res => {
        setSelectedItemSummary(res.inventorySummary);
      })
    }
  // eslint-disable-next-line
  }, [selectedItem]);


  const [description, setDescription] = useState<string>('');
  const [txnType, setTxnType] = useState<string>('credit');
  const [date, setDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [quantity, setQuantity] = useState<number>(0);
  const [rate, setRate] = useState<number>(0);
  const [amount, setAmount] = useState<number>(0);
  const [payments, setPayments] = useState<Array<{
    mode: 'cash' | 'bank';
    value: number;
    note: string;
    date: string;
    disabled?: boolean;
  }>>([ { mode: 'cash', value: 0, note: '', date: moment().format('YYYY-MM-DD') }  ]);
  const [paymentValue, setPaymentValue] = useState<number>(0);
  const [txnStatus, setTxnStatus] = useState<string>('open');

  function updatePayment({ index, value, note, mode }: { 
    index: number;
    value?: number;
    mode?: 'cash' | 'bank';
    note?: string;
  }) {
    const newPayment = payments[index];
    if (value) {
      newPayment.value = value;
    }
    if (note) {
      newPayment.note = note;
    }
    if (mode) {
      newPayment.mode = mode;
    }
    newPayment.date = moment().format('YYYY-MM-DD');

    setPayments([...payments]);
    setPaymentValue(payments.reduce((p, c) => p + c.value, 0));
  }


  useEffect(() => {
    getTopics();
    getItems();
  // eslint-disable-next-line
  }, []);


  useEffect(() => {
    if (id && isOpen) {
      getTxnById(id).then(res => {
        setDescription(res.description);
        setDate(res.date);
        setPaymentValue(res.payments.reduce((p: any, c: any) => p + c.value, 0));
        const disabledPayments = res.payments.map((p: any) => {
          return {
            disabled: true,
            ...p
          }
        })
        setPayments(disabledPayments);
        setQuantity(res.quantity);
        setRate(res.rate);
        getTopicById(res.topic.id)
          .then(result => {
            setSelectedTopic(result)
          });

        getItemById(res.item.id)
          .then(result => setSelectedItem(result));
        setTxnType(res.type);
        setAmount(res.value);
        setTxnStatus(res.transactionStatus);
      })
    }
  // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    if (rate && quantity) {
      const newAmount = (rate * quantity) + ( rate * quantity * (selectedItem?.gstSlab || 0) / 100); // TODO :add others
      setAmount(newAmount);
    }
  }, [rate, quantity, selectedItem]);

  function closeModal() {
    setIsOpen(false);
    onClose();
  }

  async function submitTransaction() {

    if (!id) {
      const res: any = {
        topic: selectedTopic,
        item: selectedItem,
        type: txnType,
        businessType: businessType,
        clientInvoice: '',
        transactionStatus: txnStatus,
        date: date,
        description: description,
        quantity: quantity,
        rate: rate,
        gst: selectedItem?.gstSlab,
        others: 0, // TODO
        othersGst: 0, // TODO
        value: amount,
        paymentValue: paymentValue,
        payments: payments.filter(p => p.value),
      };

      console.log('req to post', res);

      await set(res);
    } else {
      const updateRes = {
        id: id,
        transactionStatus: txnStatus,
        payments: payments.filter(p => p.value).map(p => {
          return {
            mode: p.mode,
            value: p.value,
            note: p.note,
            date: p.date
          }
        })
      }

      await set(updateRes);
    }

    closeModal();
  }

  function openModal() {
    setIsOpen(true)
  }

  useEffect(() => {
    validate();
  // eslint-disable-next-line
  }, [
    quantity, rate, amount, txnType, payments, paymentValue, amount, description, date,
    selectedItem, selectedTopic
  ]);

  function addPayment() {
    setPayments([ ...payments, { mode: 'cash', value: 0, note: '', date: moment().format('YYYY-MM-DD') } ]);
  }


  function validate() {

    if (!!!selectedItem?.id) {
      setErrorMessage('No Item selected');
      return;
    }
    // // Client needs to be selected
    if (!!!selectedTopic?.id) {
      setErrorMessage('No client selected');
      return;
    }

    if (['billed-real-goods', 'billed-virtual-goods'].includes(businessType) && !selectedTopic.gstNumber) {
      setErrorMessage('Cant proceed with this transaction as Client does not have GST');
    }

    // Date can't be empty
    if (!date) {
      setErrorMessage('Date is not set');
      return;
    }

    // // Amount should be greater than 0
    if (amount < 1) {
      setErrorMessage("Amount should be greater than zero");
      return;
    }


    if (paymentValue > amount) {
      setErrorMessage('Total Payment cant exceed Total Transaction Amount');
      return;
    }

    setErrorMessage('');

  }

  return (
    <>
      <div className={`${!id ? 'mx-2' : 'inline'}`}>
        <button
          type="button"
          onClick={openModal}
          className={buttonStyle || "rounded-md bg-gray-200 px-4 py-2 text-sm font-medium  border border-rose-600 text-rose-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"}
        >
          {
            !id ?  <><PlusIcon className='w-4 inline' /> Add Txn</> : <><PencilSquareIcon className='w-4 inline' /></>
          }
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Inv Transaction
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="businessType">
                        Select Business Type
                      </label>
                      <select disabled={!!id} value={businessType} onChange={(val) => {
                        setBusinessType(val.target.value)
                      }} className="bg-white appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="businessType">
                          <option value="billed-real-goods">😇 Billed Real Goods</option>
                          <option value="billed-virtual-goods">🤠 Billed Virtual Goods</option>
                          <option value="unbilled-real-goods">💀 Challan Real Good</option>
                      </select>
                    </div>

                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="type">
                        Select Type
                      </label>
                      <select disabled={!!id} value={txnType} onChange={(val) => setTxnType(val.target.value)} className="bg-white appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="type">
                          <option value="credit">📥 { calculateType(businessType, 'credit') }</option>
                          <option value="debit">📤 { calculateType(businessType, 'debit') }</option>
                      </select>
                    </div>

                    <div className="mb-4 border border-gray-200 rounded p-2">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="item">
                        Select Item: { selectedItem?.name|| <span className="text-rose-700 font-light">not-selected</span>  }
                      </label>  
                      {
                        !!id ? <div className="mb-2 border border-gray-200 text-center rounded p-2">{ selectedItem?.name }</div>
                          : <ComboBox qr={true} data={items} onChange={(val) => {
                            const selItem = items.find((i: any) => i.id === val.id);
                            if (selItem) {
                              setSelectedItem(val);
                            }

                          }} />
                      }
                      <div>
                        <div className="text-center">Stock Summary</div>
                        { 
                          calculateStockSummary(
                            selectedItemSummary?.stockSummary || {},
                            txnType as any, businessType as any, true) 
                        }
                      </div>
                      
                    </div>

                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="amount">
                        Select Client: { selectedTopic?.name || <span className="text-rose-700 font-light">not-selected</span> }
                      </label>
                      {
                        !!id ? <div className="mb-2 border border-gray-200 text-center rounded p-2">{ selectedTopic?.name }</div>
                          : <ComboBox data={topics} onChange={(val: any) => {
                            const selItem = topics.find((i: any) => i.id === val.id);
                            if (selItem) {
                              setSelectedTopic(val);
                            }                          
                          }} />
                      }
                    </div>

                    <div>
                      <div className="datepicker relative form-floating mb-3">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="date">
                          Select Date
                        </label>
                        <input type="date" id="date" disabled={!!id}
                          className="w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-700 focus:outline-none"
                          placeholder="Select a date" onChange={(val) => setDate(val.target.value)} value={date} />
                      </div>
                    </div>
                    <div className="border border-gray-200 rounded p-2 mb-2">
                      <div className="flex">
                        <div className="mb-4 flex-initial">
                          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="quantity">
                            Quantity
                          </label>
                          <input disabled={!!id} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="quantity" value={quantity} onChange={(val) => setQuantity(parseInt(val.target.value || '0'))} type="number" placeholder="Enter Quantity" />
                        </div>
                        <div className='flex-initial my-auto'>
                          X
                        </div>
                        <div className="mb-4 flex-initial">
                          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="rate">
                            Rate
                          </label>
                          <input disabled={!!id} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="rate" value={rate} onChange={(val) => setRate(parseInt(val.target.value || '0'))} type="number" placeholder="Enter Rate" />
                        </div>
                        <div className='flex-initial my-auto'>
                          =
                        </div>
                        <div className="mb-4 flex-grow">
                          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="amount">
                            Total
                          </label>
                          <div className="text-right">
                          {  formatValue(quantity * rate) }
                          </div>
                          
                          {/* <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="amount" readOnly value={amount} onChange={(val) => setAmount(parseInt(val.target.value || '0'))} type="number" placeholder="Enter Amount" /> */}
                        </div>
                      </div>
                      <div className="flex">
                        <div className="flex-grow">
                          GST Amount ({selectedItem?.gstSlab || 0} %) =
                        </div>
                        <div className="text-right">
                          { formatValue((quantity * rate * (selectedItem?.gstSlab || 0)) / 100)  }
                        </div>
                      </div>
                      <div className="flex">
                        <div className="flex-grow">Total = </div>
                        <div className="text-right">{ formatValue(amount) }</div>
                      </div>
                    </div>
                    
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                        Add Description
                      </label>
                      <input disabled={!!id} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="description"
                        value={description} onChange={(val) => setDescription(val.target.value)} type="text" placeholder="Enter Description" />
                    </div>

                    <div className="mb-2 border border-gray-200 rounded p-2">
                      <div className='flex'>
                        <div className="flex flex-grow text-lg text-gray-700 font-bold mb-2">
                          Payments { txnType === 'credit' ? 'Received': 'Made' }: { paymentValue }
                        </div>
                        <div className="inline flex-none text-gray-700 text-sm font-bold mb-2">
                          <button disabled={paymentValue === amount} onClick={addPayment} className='bg-green-700 rounded text-white px-4 text-lg' type="button">+</button>
                        </div>
                      </div>
                      <div>
                        {
                          payments.map((p, idx) => <div key={idx}>
                            <div className="flex">
                              <div className="mb-4 flex-initial">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`payment-value-${idx}`}>
                                  Value
                                </label>
                                <input disabled={payments[idx].disabled} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  id={`payment-value-${idx}`} min='0' value={payments[idx].value} onChange={(val) => updatePayment({ index: idx, value: parseInt(val.target.value || '0') })} type="number" placeholder="Enter Value" />
                              </div>
                              <div className="mb-4 flex-initial">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`payment-mode-${idx}`}>
                                  Mode
                                </label>
                                <select disabled={payments[idx].disabled} value={payments[idx].mode} onChange={(val) => updatePayment({ index: idx, mode: val.target.value as 'cash' | 'bank' })} className="bg-white appearance-none border rounded w-18 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  id={`payment-mode-${idx}`}>
                                    <option value="cash">💸 Cash</option>
                                    <option value="bank">🏦 Bank</option>
                                </select>
                              </div>
                              <div className="mb-4 flex-grow">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`payment-note-${idx}`}>
                                  Note
                                </label>
                                <input disabled={payments[idx].disabled} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  id={`payment-note-${idx}`} value={payments[idx].note} onChange={(val) => updatePayment({ index: idx, note: val.target.value }) } type="text" placeholder="Enter Note" />
                              </div>
                            </div>

                          </div>)
                        }
                      </div>
                      
                    </div>

                    <div className="mb-2 border border-gray-200 rounded p-2">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="transactionStatus">
                        Select Status
                      </label>
                      <select value={txnStatus} onChange={(val) => setTxnStatus(val.target.value)} className="bg-white appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="transactionStatus">
                          <option value="open">📖 Open</option>
                          {
                            amount === paymentValue ?  <option value="payment-done-goods-pending">💸✅ Payment 🚚❌ Goods</option> : null

                          }
                          <option value="goods-done-payment-pending">🚚✅ Goods 💸❌ Payment</option>
                          <option value="closed">📕 Closed</option>
                      </select>
                    </div>

                  </div>

                  <div className='mt-2'>
                    <div className="text-lg text-gray-700 font-bold mb-2 flex">
                      <div className="flex-grow">Balance:</div>
                      <div className="text-right"> { formatValue(amount - paymentValue) }</div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      disabled={!!errorMessage}
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-400 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:bg-gray-200"
                      onClick={submitTransaction}
                    >
                      Submit
                    </button>
                    <span className='text-red-500'>{errorMessage}</span>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

function calculateType(businessType: string, type: 'credit'|'debit'): string {
  if (businessType === 'billed-real-goods' && type === 'credit') {
    return 'Sales Order 🛒';
  }

  if (businessType === 'billed-real-goods' && type === 'debit') {
    return 'Purchase Order 🛒';
  }

  if (businessType === 'billed-virtual-goods' && type === 'credit') {
    return 'Sales Order (only Bill) 🤠';
  }

  if (businessType === 'billed-virtual-goods' && type === 'debit') {
    return 'Purchase Order (only Bill) 🤠';
  }

  if (businessType === 'unbilled-real-goods' && type === 'credit') {
    return 'Challan Sales Order 💸';
  }

  if (businessType === 'unbilled-real-goods' && type === 'debit') {
    return 'Challan Purchase Order 💸';
  }

  return ''
  
}
